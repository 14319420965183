import {
  Icon,
} from 'components/atoms'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'

const CheckList = ({ items, className, ...props }) => (
  <Wrapper
    className={ 'text-content ' + className }
    { ...props }
  >
    { items.map(item => (
      <li key={ item.key }>
        <Icon name="heavyCheck" />
        <span>{item.node}</span>
      </li>
    ),
    )}
  </Wrapper>
)

CheckList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    node: PropTypes.node.isRequired,
  })).isRequired,
}

CheckList.defaultProps = {
  className: '',
}

const Wrapper = styled.ul`
  max-width: 100%;
  padding-left: 0;
  font-size: 1.38rem;
  line-height: 2.25rem;
  list-style: none;

  li {
    display: flex;
    align-items: baseline;
    padding-bottom: 1rem;

    em {
      font-style: normal;
      font-weight: bold;
      color: ${colors.darkBlue};
    }

    svg {
      flex-shrink: 0;
      width: 0.75rem;
      margin-right: 0.75rem;
      fill: ${colors.green};
    }
  }
`

export default CheckList
