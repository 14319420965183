import Footer from 'components/Footer'
import ContactForm from 'components/forms/ContactForm'
import {
  AboutUs,
  AppPreview,
  Nav,
  PresentationContent,
  PresentationHeader,
  Subscribe,
  SubscriptionDrawer,
  Subscriptions,
  TrustGrouping,
} from 'components/landing'
import DrawerProvider from 'context/Drawer'
import styled from 'styled-components'
import { isUbipharm } from 'utils/origin'

const Landing = () => (
  <Wrapper>
    <DrawerProvider>
      <Nav />
      <Content>
        <Presentation>
          <PresentationHeader />
          <PresentationContent />
          <Subscribe />
        </Presentation>
        <AboutUs />
        { isUbipharm()
          ? <ContactForm />
          : <Subscriptions />
        }
        <TrustGrouping />
        <AppPreview />
        <Footer />
      </Content>
      <SubscriptionDrawer />
    </DrawerProvider>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Presentation = styled.section`
  display: flex;
  flex-direction: column;
`

export default Landing
