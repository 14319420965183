import { Button, Icon } from 'components/atoms'
import { ModalContext } from 'context/Modal'
import { rgba } from 'polished'
import { useContext } from 'react'
import styled from 'styled-components'
import { colors, layout } from 'theme'

const Modal = () => {
  const { state } = useContext(ModalContext)

  const {
    isOpen,
    title,
    details,
    cancel,
    validate,
  } = state

  return (
    <Wrapper $isOpen={ isOpen }>
      <Overlay $isOpen={ isOpen }>
        <ModalWrapper>
          <ModalTop>
            <ModalHeader>
              <Title>
                { title }
              </Title>
              <button
                onClick={ cancel.handleClick }
              >
                <Icon
                  fill="#9a9a9a"
                  name="close"
                  size="1.3rem"
                  stroke={ colors.lightGreen }
                  strokeWidth={ 1 }
                />
              </button>
            </ModalHeader>
            <Details>
              { details }
            </Details>
          </ModalTop>
          <ModalControls>
            <CancelButton
              variant="tertiary"
              onClick={ cancel.handleClick }
            >
              { cancel.text }
            </CancelButton>
            {
              validate && (
                <ValidateButton
                  variant="primary"
                  onClick={ validate.handleClick }
                >
                  { validate.text }
                </ValidateButton>
              )}
          </ModalControls>
        </ModalWrapper>
      </Overlay>
    </Wrapper>
  )
}

const toggleDuration = 200

const Wrapper = styled.div`
  position: fixed;
  z-index: ${layout.modalZIndex};
  display: flex;
  flex-shrink: 0;
  ${({ $isOpen }) => !$isOpen ? 'visibility: hidden;' : ''}
  width: 100vw;
  height: 100vh;
`

const Overlay = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${rgba(colors.midnightBlue, 0)};
  transition: background-color ${toggleDuration}ms ease-in-out;
  transition-delay: ${toggleDuration / 2}ms;
  ${({ $isOpen }) => $isOpen && `
    background-color: ${rgba(colors.midnightBlue, 0.4)}
  `};
`

const ModalWrapper = styled.div`
box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 40rem;
  min-height: 15rem;
  overflow: hidden;
  background-color: ${colors.white};
  border-radius: 5px;
  box-shadow: 0 3px 24px 0 rgb(0 0 0 / 43%);
`

const ModalTop = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 1.875rem 2rem;
  background-color: ${colors.white};
`

const ModalHeader = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
`

const Title = styled.h2`
  margin: 0;
  font-family: 'Brother 1816';
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  color: #284460;
`

const Details = styled.p`
  font-family: Montserrat;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: normal;
  color: #8b96a2;
  white-space: pre-line;
`

const ModalControls = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1.625rem 1.25rem;
  background-color: #f5f7fa;
`

const CancelButton = styled(Button)`
  && {
    color: #7e8a98;
  }
`

const ValidateButton = styled(Button)`
  && {
    background-color: ${colors.pink};
    background-image: none;
  }
`

export default Modal
