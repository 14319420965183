import { Button, Icon } from 'components/atoms'
import HeaderUser from 'components/HeaderUser'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'

const SlimHeader = ({ children }) => (
  <Wrapper>
    <Nav>
      <LeftPart href="/">
        <Icon
          fill={ colors.lightGreen }
          name="logo"
          size="2rem"
          stroke={ colors.lightGreen }
          strokeWidth={ 1 }
        />
        <BrandName>défimédoc</BrandName>
      </LeftPart>
      <MiddlePart>
        { children !== null
          ? children
          : (
              <Phone>
                {'Des questions ? Appelez-nous au '}
                <Anchor href={ `tel:${process.env.REACT_APP_CONTACT_PHONE}` }>
                  {process.env.REACT_APP_CONTACT_PHONE}
                </Anchor>
              </Phone>
            )}
      </MiddlePart>
      <HeaderUser slim />
    </Nav>
  </Wrapper>
)

SlimHeader.propTypes = {
  children: PropTypes.node,
}

SlimHeader.defaultProps = {
  children: null,
}

const Wrapper = styled.header`
  display: flex;
  flex-shrink: 0;
  padding: 0.4375rem 1.5625rem;
  box-shadow: 8px 2px 20px 0 rgb(0 0 0 / 12%);

  @media (width <= 680px) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
`

const Nav = styled.nav`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
`

const LeftPart = styled(Button)`
  && {
    & > svg {
      margin-right: 0.5rem;
    }
  }
`

const BrandName = styled.span`
  font-family: 'Brother 1816';
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  color: ${colors.midnightBlue};
  user-select: none;
`

const MiddlePart = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  justify-content: center;
`

const Phone = styled.p`
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  color: ${rgba(colors.midnightBlue, 0.5)};
`

const Anchor = styled.a`
  font-weight: 500;
  white-space: nowrap;
`

export default SlimHeader
