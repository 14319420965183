import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'

import ButtonContent from './ButtonContent'

const Button = ({
  id,
  className,
  title,
  children,
  form,
  color,
  icon,
  iconSize,
  size,
  variant,
  onClick,
  disabled,
  type,
  href,
  rel,
  target,
}) => {
  const actionClick = e => {
    e.preventDefault()
    onClick(e)
  }

  return (
    <>
      {
        href != null
          ? (
              <Anchor
                $disabled={ disabled }
                $size={ size }
                $variant={ variant }
                className={ className }
                color={ color }
                disabled={ disabled }
                form={ form }
                href={ href }
                icon={ icon }
                id={ id }
                rel={ href[0] !== '/' ? rel : undefined }
                target={ href[0] !== '/' ? target : undefined }
                title={ title }
                type={ type }
                onClick={ e => disabled ? e.preventDefault() : null }
              >
                <ButtonContent
                  color={ color }
                  icon={ icon }
                  iconSize={ iconSize }
                  title={ title }
                >
                  {children}
                </ButtonContent>
              </Anchor>
            )
          : (
              <ButtonStyle
                $disabled={ disabled }
                $size={ size }
                $variant={ variant }
                className={ className }
                color={ color }
                disabled={ disabled }
                form={ form }
                icon={ icon }
                id={ id }
                title={ title }
                type={ type ?? 'button' }
                onClick={ onClick != null ? actionClick : undefined }
              >
                <ButtonContent
                  color={ color }
                  icon={ icon }
                  iconSize={ iconSize }
                  title={ title }
                >
                  {children}
                </ButtonContent>
              </ButtonStyle>
            )}
    </>
  )
}

const primaryStyle = `
  background-image: linear-gradient(to bottom right, rgb(35,188,186), rgb(0,229,166));
  color: ${colors.white};
  fill: ${colors.white};

  &:active {
    box-shadow: rgba(0, 0, 0, 0.35) 0 1px 2px 0;
  }
`

const secondaryStyle = `
  border: 1px solid;
`

const tertiaryStyle = `
  font-family: 'Brother 1816';
  font-weight: bold;
  color: ${colors.lightGreen};
`

const getDisabledStyle = `
  cursor: not-allowed;
  color: #9a9a9a;
  background-color: #f5f5f5;
  background-image: none;
`

const getVariantStyle = variant => {
  switch (variant) {
    case 'primary':
      return primaryStyle
    case 'secondary':
      return secondaryStyle
    case 'tertiary':
      return tertiaryStyle
    default:
      return ''
  }
}

const getSmallStyle = `
  padding: 1.1rem 2.2rem;
`

const getMediumStyle = `
  padding: 1.2rem 4rem;
`

const getLargeStyle = `
  justify-content: center;
  font-size: 1.2rem;
  background-image: none;
  background-color: ${colors.lightGreen};
  padding: 1.3rem;
  border-radius: 0;
`

const getSizeStyle = size => {
  switch (size) {
    case 'small':
      return getSmallStyle
    case 'medium':
      return getMediumStyle
    case 'large':
      return getLargeStyle
    default:
      return ''
  }
}

const style = (variant, size, disabled) => `
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  padding: 0.8rem 1rem;
  font-family: 'Montserrat';
  font-size: 1em;
  font-style: normal;
  font-weight: 800;
  color: ${ colors.lightGreen };
  cursor: pointer;
  user-select: none;
  background-color: ${colors.transparent};
  border-radius: 4px;
  outline: none;

  ${getVariantStyle(variant)}

  ${getSizeStyle(size)}

  ${disabled && getDisabledStyle}
`

const Anchor = styled.a(({ $variant, $size, $disabled }) => `
  ${style($variant, $size, $disabled)}
`)

const ButtonStyle = styled.button(({ $variant, $size, $disabled }) => `
  ${style($variant, $size, $disabled)}
`)

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  id: PropTypes.string,
  rel: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  target: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  onClick: PropTypes.func,
}

Button.defaultProps = {
  children: null,
  className: null,
  color: null,
  disabled: null,
  form: null,
  href: null,
  icon: null,
  iconSize: null,
  id: null,
  onClick: null,
  rel: null,
  size: null,
  target: null,
  title: null,
  type: null,
  variant: null,
}

export default Button
