import calendarImg from 'assets/images/dpc/calendar.svg'
import notesImg from 'assets/images/dpc/notes.svg'
import {
  CheckList,
} from 'components/atoms'
import StatisticsBanner from 'components/dpc/StatisticsBanner'
import { PageMainWrapper } from 'components/dpc/style'
import Separator from 'components/landing/Separator'
import DpcLayout from 'components/layouts/DpcLayout'
import { APPLE_STORE_APP_URL, PLAY_STORE_APP_URL } from 'constants/link'
import styled from 'styled-components'
import { colors, sizes } from 'theme'
import { downloadRules } from 'utils/file'

const Training = () => (
  <DpcLayout>
    <Wrapper>
      <h1 className="text-heading">Le parcours DPC du CNP</h1>
      <section className="introduction">
        <h2>
          Le
          {' '}
          <em>
            parcours DPC « Défimédoc » du CNP Pharmacie comporte 100 Quiz&nbsp;:
          </em>
        </h2>
        <ul>
          <li>35 Quiz sur des classes de médicaments </li>
          <li>35 Quiz « Cas pratiques » </li>
          <li>12 Quiz Médic&quot;Actus (nouveaux médicaments, nouvelles recommandations…) et 12 Quiz Actualités</li>
          <li>Et l’été des Quiz de révision&nbsp;: les « Mélimélo Quiz »</li>
        </ul>
      </section>
      <Separator />
      <section className="in-details">
        <h1 className="text-heading">La formation en détail</h1>
        <h2 className="text-subheading">Public et prérequis</h2>
        <CheckList items={ prerequisites } />
      </section>
      <aside>
        <h2 className="text-subheading">Les objectifs</h2>
        <p className="text-content">
          La formation Défimédoc a pour finalité la mise à jour des connaissances sur le médicament en tenant compte des dernières données de la science (pharmacovigilance, alertes ANSM, nouvelles recommandations HAS...).
        </p>
        <p className="text-content">
          A l&apos;issue de la formation, l’apprenant saura :
        </p>
        <CheckList
          items={ objectives }
        />
      </aside>
      <section className="details-grid">
        <div className="duration">
          <h2 className="text-subheading">Durée de la formation</h2>
          <p className="text-content">
            La durée de la formation est de un an. La formation est délivrée progressivement. Elle ne peut pas être terminée avant le délai prévu d’un an.
          </p>
        </div>
        <Calendar>
          <img src={ calendarImg } />
        </Calendar>
        <div className="delay">
          <h2 className="text-subheading">Délai d&apos;accès</h2>
          <p className="text-content">
            Il est possible de s’inscrire à tout moment de l&apos;année, du 1er janvier au 31 décembre. Le DPC sera validé un an plus tard.
          </p>
        </div>
        <div className="means">
          <h2 className="text-subheading">Moyens</h2>
          <p className="text-content">
            Le contenu pédagogique (Fiches l&apos;Essentiel, Quiz, Scores, Histogrammes de positionnement, dialogue
            avec le responsable pédagogique) est disponible
            {' '}
            <em>via l’application mobile Défimédoc.</em>
          </p>
          <p className="text-content">
            Le téléchargement de l&apos;application mobile Défimédoc se fait sur
            {' '}
            <a
              href={ APPLE_STORE_APP_URL }
              rel="noreferrer"
              target="_blank"
            >
              Applestore
            </a>
            {' '}
            ou
            {' '}
            <a
              href={ PLAY_STORE_APP_URL }
              rel="noreferrer"
              target="_blank"
            >
              Playstore
            </a>
            {' '}
            . Le bon fonctionnement de l&apos;application nécessite une version minimum iOs11 (iphone 6 et suivant) ou un
            téléphone fonctionnant sous android.
          </p>
          <p className="text-content">
            L&apos;inscription à l&apos;application nécessite d’être professionnel de santé ou étudiant en santé.
            L&apos;accès au compte est strictement individuel.
            Les codes de connexion sont générés automatiquement lors de l'inscription.
          </p>
          <p className="text-content">
            La connexion à l&apos;application mobile est possible 24h/24h 7 jours sur 7 grâce à un identifiant de
            connexion et un mot de passe.
          </p>
          <p className="text-content">
            L’apprenant peut organiser son déroulé de formation à sa guise en fonction de ses contraintes personnelles.
          </p>
          <p className="text-content">
            <em>
              Pour valider sa formation, l&apos;apprenant doit avoir validé au minimum 60% des Quiz sur une année
            </em>
            {' '}
            .
          </p>
        </div>
        <Notes>
          <img src={ notesImg } />
        </Notes>
        <div className="supports">
          <h2 className="text-subheading">Supports pédagogiques</h2>
          <p className="text-content">
            <em>Les fiches &quot;L&apos;essentiel&quot;</em>
            {' '}
            en 10 points sont disponibles dans l&apos;onglet &quot;Essentiels&quot; de l&apos;application et
            consultables en ligne.
            Elles sont rédigées par des Dr en pharmacie à partir des sites officiels : Haute Autorité de Santé, ansm,
            medicaments.gouv.fr, pharmacologie.com... et selon le Cahier des charges des Fiches Défimédoc. Ces fiches sont mises à jour en temps réel en fonction de l&apos;actualité et au minimum une fois par an.
          </p>
          <p className="text-content">
            Chaque fiche comporte
            {' '}
            <em>une bibliographie</em>
            {' '}
            avec liens cliquables en ligne.
          </p>
        </div>
        <div className="evaluation">
          <h2 className="text-subheading">Méthodes et modalités d&apos;évaluation</h2>
          <p className="text-content">
            <em className="bold-italic">
              L&apos;évaluation est continue.
              <br />
            </em>
            {' '}
            Les scores apparaissent tout au long de la formation, à chaque Quiz.
            Chaque Quiz peut être refait de façon à améliorer son score.
            Le score cumulé apparaît sous forme de graphique mois par mois.
          </p>
        </div>
        <div className="prices">
          <h2 className="text-subheading">Tarifs</h2>
          <p className="text-content">
            <em>En abonnement individuel&nbsp;: 11,99 € TTC</em>
            {' '}
            (9,99€ HT)/mois ou 107,99 € TTC (89,99 € HT) pour l’année (tarifs
            {' '}
            <em>
              au 1er mars 2025, susceptibles de modifications
            </em>
            {/* */}
            )
          </p>
          <p className="text-content">
            <em>En abonnement d’équipe&nbsp;: tarif selon partenariat.</em>
          </p>
          <p className="text-content">
            Il n’est pas prévu d&apos;
            {' '}
            <em>indemnisation ANDPC du pharmacien titulaire ni de prise en charge par l’OPCO-EP ou le FIF-PL</em>
            {' '}
            .
          </p>
        </div>
        <div className="accessibility">
          <h2 className="text-subheading">Accessibilité aux personnes handicapées</h2>
          <p className="text-content bold-italic">
            L&apos;application Défimédoc peut être téléchargée sur tablette afin d&apos;agrandir les caractères en cas
            de handicap visuel.
          </p>
          <p className="text-content">
            Pour tout autre handicap, merci de nous contacter au
            {' '}
            <em className="phone-number">{process.env.REACT_APP_CONTACT_PHONE}</em>
            {' '}
            : nous étudierons avec vous toute possibilité d&apos;adaptation de la formation,
            en lien avec l&apos;Agefiph (Association de gestion du fonds pour l&apos;insertion des personnes
            handicapées).
          </p>
        </div>
        <div className="rules">
          <h2 className="text-subheading">Règlement intérieur</h2>
          <p className="text-content">
            Vous pouvez dès à présent télécharger le
            {' '}
            <button className="download"
              onClick={ downloadRules }
            >
              règlement intérieur
            </button>
            {' '}
            de nos parcours de formation.
          </p>
        </div>
        <div className="contact">
          <h2 className="text-subheading">Contact</h2>
          <p className="text-content">
            Pour tout problème technique ou toute autre demande de renseignement, l’Equipe Défimédoc peut être contactée par mail
            {' '}
            <a href={ `mailto:${process.env.REACT_APP_CONTACT_ADDRESS}` }>{process.env.REACT_APP_CONTACT_ADDRESS}</a>
            {' '}
            ou téléphone au
            {' '}
            <em>{process.env.REACT_APP_CONTACT_PHONE}</em>
            {/* */}
            ,
            de 9h à 18h du lundi au vendredi.
            Réponse sous 24 heures ouvrées.
          </p>
        </div>
      </section>
    </Wrapper>
    <StatisticsBanner />
  </DpcLayout>
)

const Calendar = styled.div`
  grid-area: calendar-img;
  margin: auto;
`

const Notes = styled.div`
  grid-area: notes-img;
  margin: auto;
`

const Wrapper = styled(PageMainWrapper)`
  padding: ${sizes.heroesPaddingTop} 1rem 5rem;

  h2 {
    font-family: Montserrat;
  }

  .introduction {
    margin: 0 auto;
    color: ${colors.darkBlue};
    text-align: center;

    p {
      margin-bottom: 1rem;
    }

    ul {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 2rem;
      font-family: Montserrat;
      font-size: 1.38rem;
      list-style: none;
    }

    li {
      position: relative;
      padding-left: 2em;
      margin-bottom: 0.75rem;
      text-align: left;

      &::before {
        position: absolute;
        top: 0.25em;
        left: 0;
        width: 10px;
        height: 10px;
        content: "";
        background-color: ${colors.green};
        border-radius: 50%;
      }
    }
  }

  ${Separator} {
    margin: 3rem auto 2rem;
  }

  aside {
    padding: 1rem 1.75rem 0;
    color: ${colors.white};
    background-color: ${colors.hanBlue};
    border-radius: 10px;

    h2 {
      margin-top: 0;
      color: ${colors.white};
    }

    p {
      padding-bottom: 1rem;
      white-space: break-spaces;
    }

    em {
      color: ${colors.white};
    }

    .green {
      font-weight: bold;
      color: ${colors.lightGreen};
    }
  }

  em {
    font-weight: bold;
  }

  .bold-italic {
    font-style: italic;
    font-weight: bold;
  }

  .in-details {
    h2 {
      margin-bottom: 2rem;
    }

    ul {
      color: ${colors.darkBlue};
    }
  }

  .phone-number {
    white-space: nowrap;
  }

  .details-grid {
    display: grid;
    grid-template:
      "duration calendar-img"
      "empty1   delay"
      "means    notes-img"
      "empty2   supports"
      "tests    empty3"
      "empty4   evaluation"
      "prices   empty5"
      "empty6   accessibility"
      "rules    empty7"
      "empty8   contact" / 1fr 1fr;
    color: ${colors.darkBlue};

    > * {
      padding: 0 1rem;
    }

    ul {
      padding-left: 1.25rem;
    }

    li > div {
      display: flex;
    }

    li > div em {
      flex-shrink: 0;
      margin-right: 0.25rem;
    }

    p, li {
      margin: 1rem 0;
    }

    a, .download {
      font-weight: bold;
      color: ${colors.purple};
      text-decoration: underline;
    }

    &::after {
      grid-row: 1 / 11;
      grid-column-start: 2;
      pointer-events: none;
      content: "";
      border-left: 1px solid ${colors.purple};
    }
  }

  .delay {
    grid-area: delay;
  }

  .means {
    grid-area: means;
  }

  .supports {
    grid-area: supports;
  }

  .tests {
    grid-area: tests;
  }

  .evaluation {
    grid-area: evaluation;
  }

  .prices {
    grid-area: prices;
  }

  .accessibility {
    grid-area: accessibility;
  }

  .rules {
    grid-area: rules;
  }

  .contact {
    grid-area: contact;
  }

  @media (width <= 1050px) {
    .details-grid {
      grid-template:
        "duration"
        "calendar-img"
        "delay"
        "means"
        "supports"
        "notes-img"
        "tests"
        "evaluation"
        "prices"
        "accessibility"
        "rules"
        "contact" / 1fr;

      &::after {
        display: none;
      }
    }
  }
`

const prerequisites = [
  {
    key: '1',
    node: (
      <span>
        Le programme s’adresse aux
        {' '}
        <em>pharmaciens</em>
        {' '}
        d&apos;officine titulaires ou adjoints.
      </span>
    ),
  },
  {
    key: '2',
    node: (
      <span>
        Il n’y a pas de prérequis spécifiques.
      </span>
    ),
  },
]

const objectives = [
  {
    key: '1',
    node: (
      <span>
        Vérifier pour le domaine thérapeutique traité que la prescription répond à tous les critères de sécurisation de la dispensation.
      </span>
    ),
  },
  {
    key: '2',
    node: (
      <span>
        Identifier des modifications ou des adaptations si nécessaire, savoir conseiller le patient pour la prise optimale du traitement et faciliter l&apos;observance, anticiper les effets indésirables et donner les conseils adéquats pour éviter leur apparition ou limiter leurs conséquences.
      </span>
    ),
  },
  {
    key: '3',
    node: (
      <span>
        Faire preuve de vigilance sur les nouveautés en matière de bon usage des médicaments.
      </span>
    ),
  },
]

export default Training
