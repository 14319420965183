import { BarController, BarElement, CategoryScale, Chart, LinearScale, Tooltip } from 'chart.js'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

const data = {
  type: 'bar',
  data: {
    labels: [],
    datasets: [
      {
        label: 'Nb de quiz fait*',
        backgroundColor: colors.blueChart,
        data: [],
      },
      {
        label: 'Nb de quiz validés',
        backgroundColor: colors.lightGreen,
        data: [],
      },
      {
        label: 'Nb de quiz refaits',
        backgroundColor: colors.deepGold,
        data: [],
      },
    ],
  },
}

const AverageScore = ({ scores, formatedMonthYears }) => {
  const canvas = useRef(null)

  useEffect(() => {
    Chart.register(CategoryScale, LinearScale, BarController, BarElement, Tooltip)

    new Chart(canvas.current.getContext('2d'), {
      ...data,
      data: {
        ...data.data,
        labels: formatedMonthYears,
        datasets: [{
          ...data.data.datasets[0],
          data: scores.map(score => score.averageQuizDone),
          barThickness: canvas.current.offsetWidth > 768 ? 40 : 15,
        },
        {
          ...data.data.datasets[1],
          data: scores.map(score => score.averageQuizValidated),
          barThickness: canvas.current.offsetWidth > 768 ? 40 : 15,
        },
        {
          ...data.data.datasets[2],
          data: scores.map(score => score.averageQuizRedone),
          barThickness: canvas.current.offsetWidth > 768 ? 40 : 15,
        }],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
          },
        },
      },
    })
  })

  return (
    <Wrapper>
      <Title>
        <h3>Moyennes mensuelles de l&apos;équipe</h3>
        <LegendWrapper>
          <Legend>
            <Dot $color={ colors.blueChart } />
            <LegendName>
              Nb de quiz faits*
            </LegendName>
          </Legend>
          <Legend>
            <Dot $color={ colors.lightGreen } />
            <LegendName>
              Nb de quiz validés
            </LegendName>
          </Legend>
          <Legend>
            <Dot $color={ colors.deepGold } />
            <LegendName>
              Nb de quiz refaits
            </LegendName>
          </Legend>
        </LegendWrapper>
      </Title>
      <canvas height="400"
        id="averageScore"
        ref={ canvas }
      >
      </canvas>
      <Info>
        <InfoBold>* Quiz fait</InfoBold>
        {'* Quiz terminé, validé ou non. Un Quiz est validé pour un score >= à 70/100 points.'}
      </Info>
    </Wrapper>
  )
}

AverageScore.propTypes = {
  formatedMonthYears: PropTypes.arrayOf(PropTypes.string).isRequired,
  scores: PropTypes.arrayOf(PropTypes.shape({
    averageQuizDone: PropTypes.number.isRequired,
    averageQuizValidated: PropTypes.number.isRequired,
    averageQuizRedone: PropTypes.number.isRequired,
  })).isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    font-family: Montserrat;
    color: ${colors.deepBlue};
  }

  @media (width <= 680px) {
    flex-direction: column;
  }
`

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

const Legend = styled.div`
  display: flex;
  flex-direction: colum;
  align-items: center;
`

const Dot = styled.span`
  display: inline-block;
  min-width: 0.875rem;
  min-height: 0.875rem;
  background-color: ${({ $color }) => $color};
  border-radius: 100%;
`

const LegendName = styled.span`
  margin-right: 1.875rem;
  margin-left: 0.5rem;
  font-family: 'Brother 1816';
  font-size: 0.875rem;
  color: ${colors.deepBlue};
  opacity: 0.5;
`

const Info = styled.span`
  font-family: 'Brother 1816';
  font-size: 0.875rem;
  color: ${colors.darkGray};
`

const InfoBold = styled.span`
  font-weight: 600;
`

export default AverageScore
