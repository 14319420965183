import Markdown from 'components/atoms/Markdown'
import PolicyLayout from 'components/layouts/PolicyLayout'
import styled from 'styled-components'

const LegalNotice = () => (
  <PolicyLayout>
    <Title>Mentions légales</Title>
    <Markdown>
      {
        `
- www.defimedoc.fr est un site édité par Défimédoc, société par actions simplifiée au capital de 5 000 euros, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro SIREN 852.559.822 et dont le siège social est situé 15 T Boulevard du Maréchal Foch, 49100 Angers.

- N° de TVA intracommunautaire : FR65 852559822

- Tél : ${process.env.REACT_APP_CONTACT_PHONE} - E-mail : ${process.env.REACT_APP_CONTACT_ADDRESS}

- Directeur de la publication : Madame Florence Bontemps, Présidente de Défimédoc

- Conception et conception graphique : KNP-Labs

- Hébergement : OVH, 2 rue Kellermann - 59100 Roubaix - France`
      }
    </Markdown>
  </PolicyLayout>
)

const Title = styled.h1`
  font-family: 'Brother 1816';
  font-style: normal;
  font-weight: 900;
  text-align: center;
`

export default LegalNotice
