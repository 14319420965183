import * as images from 'assets/svgs'
import PropTypes from 'prop-types'

const Icon = ({
  name,
  size,
  href,
  fill,
  stroke,
  strokeWidth,
  className,
  onClick,
  target,
  rel,
}) => {
  const Image = images[name]

  return (
    href !== null
      ? (
          <a href={ href }
            rel={ rel }
            target={ target }
          >
            <Image
              className={ className }
              data-testid={ `${name}-icon` }
              fill={ fill }
              height={ size }
              stroke={ stroke }
              strokeWidth={ strokeWidth }
              width={ size }
              onClick={ onClick }
            />
          </a>
        )
      : (
          <Image
            className={ className }
            data-testid={ `${name}-icon` }
            fill={ fill }
            height={ size }
            stroke={ stroke }
            strokeWidth={ strokeWidth }
            width={ size }
            onClick={ onClick }
          />
        )
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  href: PropTypes.string,
  name: PropTypes.oneOf(Object.keys(images)).isRequired,
  rel: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
  target: PropTypes.string,
  onClick: PropTypes.func,
}

Icon.defaultProps = {
  className: null,
  fill: null,
  href: null,
  onClick: null,
  rel: null,
  size: 16,
  stroke: null,
  strokeWidth: null,
  target: null,
}

export default Icon
