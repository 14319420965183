import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { colors } from 'theme'

const Markdown = ({ children }) => (
  <ReactMarkdown>
    { children }
  </ReactMarkdown>
)

Markdown.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledMarkdown = styled(ReactMarkdown)`
  && {
    margin: 1rem 0;
    color: ${colors.black};
  }

  & strong {
    color: ${colors.lightGreen};
  }
`

export default StyledMarkdown
