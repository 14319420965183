import { rgba } from 'polished'
import { type FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

type Props = {
  crumbs: string[]
  currentCrumbIndex: number
}

const BreadCrumbs: FC<Props> = ({
  crumbs,
  currentCrumbIndex,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const HEIGHT = 60
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth / crumbs.length)
    }
  }, [ref, crumbs])

  const pointyEnd = `${width - 15} 0, ${width} ${HEIGHT / 2}, ${width - 15} ${HEIGHT}`

  return (
    <Wrapper ref={ ref }>
      { Object.values(crumbs).map((crumb, index) => (
        <svg
          height={ HEIGHT }
          key={ index }
          viewBox={ `0 0 ${width} ${HEIGHT}` }
        >
          <defs>
            <linearGradient
              gradientTransform="rotate(99)"
              id="myGradient"
            >
              <stop
                offset="5%"
                stopColor={ colors.seaGreen }
              />
              <stop
                offset="95%"
                stopColor={ colors.lightGreen }
              />
            </linearGradient>
          </defs>
          <polygon
            fill={ index === currentCrumbIndex ? 'url(\'#myGradient\')' : 'none' }
            points={ `0 0, ${pointyEnd}, 0 ${HEIGHT}` }
          />
          { index === crumbs.length - 2
            ? (
                <PointyEnd
                  points={ pointyEnd }
                />
              )
            : null }
          <Text
            $isCurrent={ index === currentCrumbIndex }
            x="33%"
            y="60%"
          >
            { crumb }
          </Text>
        </svg>
      ),
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  border-bottom: solid 2px ${rgba(colors.green, 0.7)};
`

type TextType = {
  $isCurrent: boolean
}

const Text = styled.text<TextType>`
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: ${({ $isCurrent }) => $isCurrent ? 'bold' : 'normal' };
  user-select: none;
  ${({ $isCurrent }) => $isCurrent
    ? `fill: ${colors.white};`
    : `fill: ${rgba(colors.midnightBlue, 0.5)};`}
`

const PointyEnd = styled.polyline`
  fill: none;
  stroke: rgb(6 38 79 / 18%);
  stroke-width: 1;
`

export default BreadCrumbs
