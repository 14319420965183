import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { NavItemLi } from './style'

const NavItem = ({ active, to, children, ...props }) => {
  return (
    <NavItemLi className={ active ? 'active' : 'inactive' }>
      <Link to={ to }
        { ...props }
      >
        {children}
      </Link>
    </NavItemLi>
  )
}

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
}

NavItem.defaultProps = {
  active: false,
}

export default NavItem
