import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'

const FigureItem = ({
  alt,
  description,
  image,
  title,
  url,
}) => (
  <ListItemWrapper>
    <Wrapper>
      { url
        ? (
            <a href={ url }
              rel="noreferrer"
              target="_blank"
            >
              <Image
                alt={ alt }
                src={ image }
              />
            </a>
          )
        : (
            <Image
              alt={ alt }
              src={ image }
            />
          )}
      <StyledFigcaption>
        <Title>
          {title}
        </Title>
        <Description>
          {description}
        </Description>
      </StyledFigcaption>
    </Wrapper>
  </ListItemWrapper>
)

FigureItem.defaultProps = {
  alt: null,
  description: null,
  title: null,
  url: null,
}

FigureItem.propTypes = {
  alt: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  url: PropTypes.string,
}

const ListItemWrapper = styled.li`
  list-style-type: none;
`

const Wrapper = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Image = styled.img`
  max-height: 100px;
`

const StyledFigcaption = styled.figcaption`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  margin-bottom: 0.5rem;
  font-family: 'Brother 1816';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.375rem;
  color: ${colors.darkBlue};
`

const Description = styled.span`
  font-family: 'Brother 1816';
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.375rem;
  color: ${colors.darkBlue};
`

export default FigureItem
