import Footer from 'components/Footer'
import SlimHeader from 'components/SlimHeader'
import { type FC, type PropsWithChildren } from 'react'
import styled from 'styled-components'

const PaymentLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Wrapper>
      <SlimHeader />
      { children }
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export default PaymentLayout
