import { useStripe } from '@stripe/react-stripe-js'
import { ModalContext } from 'context/Modal'
import { useCallback, useContext, useEffect } from 'react'
import { toast } from 'react-toastify'

const useThreeDSecure = (on3DSSucceeded: () => void) => {
  const stripe = useStripe()
  const { actions: { toggleThreeDSModal, closeThreeDSModal } } = useContext(ModalContext)

  const handleIframeResponse = useCallback(async (evt: MessageEvent) => {
    if (evt.data.completed === '3DS-authentication-complete') {
      const paymentButton = document.getElementById('payment-button') as HTMLButtonElement|null

      if (!stripe) {
        throw new Error('Stripe is not loaded')
      }

      stripe.retrieveSetupIntent(evt.data.secret)
        .then(function(result) {
          const iframe = document.getElementById('3dsiframe')

          if (iframe != null) {
            iframe.remove()
          }

          closeThreeDSModal()

          if (result.error) {
            toast.error('Oups ! Un problème est survenu', {
              position: 'top-center',
            })

            if (paymentButton != null) {
              paymentButton.disabled = false
            }
          } else if (result.setupIntent.status === 'succeeded') {
            on3DSSucceeded()
          } else if (result.setupIntent.status === 'requires_payment_method') {
            toast.error('Votre 3D Secure n\'a pas été validé', {
              position: 'top-center',
            })

            if (paymentButton != null) {
              paymentButton.disabled = false
            }
          }
        })
    }
  }, [stripe, on3DSSucceeded, closeThreeDSModal])

  useEffect(() => {
    window.addEventListener('message', handleIframeResponse)

    return () => {
      window.removeEventListener('message', handleIframeResponse)
    }
  }, [handleIframeResponse])

  const createThreeDSIframe = useCallback((threeDSRedirectUrl: string) => {
    const threeDSModalContent = document.getElementById('threeDSModalContent')

    if (null == threeDSModalContent) {
      throw new Error('Unable to create 3DS iframe')
    }

    const iframe = document.createElement('iframe')
    iframe.setAttribute('id', '3dsiframe')
    iframe.src = threeDSRedirectUrl
    iframe.width = '100%'
    iframe.height = '100%'
    threeDSModalContent.appendChild(iframe)
  }, [])

  const handle3DS = useCallback(async (threeDSRedirectUrl: string) => {
    createThreeDSIframe(threeDSRedirectUrl)
    toggleThreeDSModal()

  }, [createThreeDSIframe, toggleThreeDSModal])

  return {
    handle3DS,
  }
}

export default useThreeDSecure
