import { PageMainWrapper } from 'components/dpc/style'
import DpcLayout from 'components/layouts/DpcLayout'
import styled from 'styled-components'
import { colors, sizes } from 'theme'

const Team = () => {
  const scientificCommittee = [
    {
      name: 'Dr Nadine BERTONI',
      role: 'Psychiatre',
    },
    {
      name: 'Dr Pascale DUGAS',
      role: 'Pharmacien hospitalier',
    },
    {
      name: 'Pr Nicolas PICARD',
      role: 'Pharmacien hospitalier',
    },
    {
      name: 'Dr Arthur PIRAUX',
      role: 'Pharmacien officinal',
    },
    {
      name: 'Dr Sophie REVAUX',
      role: 'Pharmacien officinal',
    },
  ]

  return (
    <DpcLayout>
      <Wrapper>
        <h1 className="text-heading">L&apos;équipe Défimédoc</h1>
        <p className="text-content intro">
          Les Quiz sont rédigés par des Dr en pharmacie selon le Cahier des Charges élaboré par Défimédoc
          et validés par un Comité Scientifique.
        </p>
        <h2 className="text-subheading">Sous la responsabilité de</h2>
        <section className="florence">
          <img
            alt="Florence Bontemps"
            height="315"
            src={ require('assets/images/florence.png') }
          />
          <span>
            <h3 className="text-content">Florence Bontemps</h3>
            <p className="text-content role">Présidente de Défimédoc</p>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Conceptrice de l&apos;application Défimédoc
            </p>
          </span>
        </section>
        <h2 className="text-subheading">Le Comité scientifique</h2>
        <section className="collaborators">
          {scientificCommittee.map(member => (
            <div className="text-content" key={ member.name }>
              <h3>{member.name}</h3>
              <p className="text-content">
                {member.role}
              </p>
            </div>
          ))}
        </section>
        <h2 className="text-subheading">Les collaborateurs</h2>
        <section className="collaborators">
          <div className="text-content">
            <h3>Nathalie Belin</h3>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Rédactrice pour des revues professionnelles : Moniteur des pharmacies, Porphyre, Infirmière Libérale Magazine
            </p>
          </div>
          <div className="text-content">
            <h3>Romain Poulin</h3>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Pharmacien officinal
              <br />
              Directeur du développement de Défimédoc
              <br />
            </p>
          </div>
          <div className="text-content">
            <h3>Florence Leandro</h3>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Enseignante CQP de dermo-cosmétique et des étudiants de 4ème et 5ème année Officine.
              <br />
              Rédactrice pour des revues professionnelles : Le Moniteur des Pharmacies, Infirmière Libérale Magazine,
              Médecine & Enfance.
            </p>
          </div>
          <div className="text-content">
            <h3>Joël Schofield</h3>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Pharmacien officinal
              <br />
              Formateur en centre de formation des apprentis
            </p>
          </div>
          <div className="text-content">
            <h3>David Paitraud</h3>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Pharmacien officinal
              <br />
              Rédacteur pour des médias professionnels : Le Quotidien du Pharmacien, Pharmaradio, Vidal.fr
            </p>
          </div>
          <div className="text-content">
            <h3>Céline Bizot</h3>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Pharmacienne officinale
              <br />
              Enseignante à la Faculté de pharmacie d&apos;Amiens
            </p>
          </div>

          <div className="text-content">
            <h3>Manon Dufetel</h3>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Pharmacienne officinale
            </p>
          </div>
          <div className="text-content">
            <h3>Line Chapelain</h3>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Pharmacienne officinale
              <br />
              Pharmacienne au CRATB Pays de la Loire
            </p>
          </div>
          <div className="text-content">
            <h3>Baptiste Hallopé</h3>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Interne en Pédiatrie (Étudiant en Médecine)
            </p>
          </div>
          <div className="text-content">
            <h3>Marjolaine Labertonière</h3>
            <p className="text-content">
              Dr en pharmacie
            </p>
          </div>
          <div className="text-content">
            <h3>Laurence Coiffard</h3>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Enseignant-chercheur à la Faculté de Pharmacie de Nantes
            </p>
          </div>
          <div className="text-content">
            <h3>Pascale Dugast</h3>
            <p className="text-content">
              Dr en pharmacie
              <br />
              Pharmacien Hospitalier
            </p>
          </div>
        </section>
      </Wrapper>
    </DpcLayout>
  )
}

const Wrapper = styled(PageMainWrapper)`
  padding: ${sizes.heroesPaddingTop} 1rem 0;
  margin-bottom: 2rem;
  color: ${colors.darkBlue};

  h2 {
    margin: 4rem 0 3rem;
    text-align: center;
  }

  h3 {
    margin-bottom: 1rem;
    font-weight: 700;
  }

  .intro {
    text-align: center;
  }

  section.florence {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 3rem;
    }

    h3 {
      margin: 0;
      line-height: 22px;
    }

    p {
      line-height: 22px;
    }

    .role {
      margin: 0 0 3rem;
      text-transform: uppercase;
    }
  }

  section.collaborators {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(2, 25rem);
    gap: 1rem;
    justify-content: center;
    color: ${colors.darkBlue};

    p {
      line-height: 22px;
    }
  }

  @media (width <= 900px) {
    padding-top: ${sizes.heroesMobilePaddingTop};

    section.collaborators {
      grid-template-rows: repeat(6, auto);
      grid-template-columns: repeat(1, 25rem);
    }
  }

  @media (width <= 600px) {
    .florence {
      flex-direction: column;

      img {
        margin-bottom: 2rem;
      }
    }
  }
`

export default Team
