import { toast } from 'react-toastify'

type GraphQLError = {
  message: string
}

type GraphQLErrors = {
  graphQLErrors: GraphQLError[]
}

const useGraphQLErrors = () => {
  const isGraphQLErrors = (error: unknown): error is GraphQLErrors => {
    return error != null
      && typeof error === 'object'
      && 'graphQLErrors' in error
      && error.graphQLErrors instanceof Array
      && error.graphQLErrors.every((graphQLError: unknown) => {
        return graphQLError != null
          && typeof graphQLError === 'object'
          && 'message' in graphQLError
          && typeof graphQLError.message === 'string'
      })
  }

  const handleGraphQLErrors = (
    error: unknown,
    messages?: Record<string, string>,
    genericMessage: string = 'Oups, une erreur est survenue',
  ): void => {
    if (isGraphQLErrors(error)) {
      if (error.graphQLErrors.length > 0) {
        error.graphQLErrors.forEach(graphQLError => {
          const message = messages?.[graphQLError.message] ?? genericMessage

          toast.error(message, { position: 'top-center' })
        })

        return
      }
    }

    toast.error(genericMessage, { position: 'top-center' })
  }

  return { handleGraphQLErrors }
}

export default useGraphQLErrors
