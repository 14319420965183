import { Icon } from 'components/atoms'
import { ModalContext } from 'context/Modal'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import styled from 'styled-components'
import { colors, layout } from 'theme'

import Separator from './landing/Separator'

const ModalDailyQuestion = ({ dailyQuestion }) => {
  const { state, actions } = useContext(ModalContext)

  const {
    isDailyQuestionModalOpen,
  } = state

  const handleModalClose = actions.toggleDailyQuestionModal

  let subscriptionTarget = 0
  if (document.getElementById('subscriptions') !== null) {
    subscriptionTarget = document.getElementById('subscriptions').offsetTop
  }

  const handleScrollToSubscription = () => (handleModalClose, window.scrollTo({
    top: subscriptionTarget,
    behavior: 'smooth',
  }))

  return (
    <Wrapper
      isOpen={ isDailyQuestionModalOpen }
      onClick={ handleModalClose }
    >
      <Overlay isOpen={ isDailyQuestionModalOpen }>
        <ModalWrapper isOpen={ isDailyQuestionModalOpen }>
          <StyledIcon
            fill={ colors.white }
            name="close"
            size="1.7rem"
            onClick={ handleModalClose }
          />
          <ModalContent>
            <Title>Testez-vous</Title>
            <DailyQuestionContent
              dangerouslySetInnerHTML={ {
                __html: dailyQuestion.title,
              } }
            />
            <Separator />
            <DailyQuestionAnswer
              dangerouslySetInnerHTML={ {
                __html: dailyQuestion.answer,
              } }
            />
            <SubscribeWording>
              <button
                onClick={ handleScrollToSubscription }
              >
                <StyledButton>Abonnez-vous</StyledButton>
                {' pour accéder à tous nos quizz'}
              </button>
            </SubscribeWording>
          </ModalContent>
        </ModalWrapper>
      </Overlay>
    </Wrapper>
  )
}

ModalDailyQuestion.propTypes = {
  dailyQuestion: PropTypes.shape({
    title: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
  }).isRequired,
}

const toggleDuration = 200

const Wrapper = styled.div`
  position: fixed;
  z-index: ${layout.modalZIndex};
  display: flex;
  flex-shrink: 0;
  ${({ isOpen }) => !isOpen ? 'visibility: hidden;' : ''}
  width: 100%;
  height: 100%;
`

const Overlay = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${rgba(colors.midnightBlue, 0)};
  transition: background-color ${toggleDuration}ms ease-in-out;
  transition-delay: ${toggleDuration / 2}ms;
  ${({ isOpen }) => isOpen && `
    background-color: ${rgba(colors.midnightBlue, 0.4)};
  `};

  @media (width <= 820px) {
    width: 100%;
  }
`

const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 15rem;
  font-family: 'Brother 1816';
  color: ${colors.darkBlue};
  text-align: center;
  background-color: ${colors.white};
  border-radius: 5px;
  box-shadow: 0 3px 24px 0 rgb(0 0 0 / 43%);
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.5rem;
`

const Title = styled.h2`
  font-family: 'Brother 1816';
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 900;
  line-height: 3.75rem;
  color: ${colors.midnightBlue};

  @media (width <= 1050px) {
    font-size: 2rem;
    line-height: 3rem;
  }
`

const DailyQuestionContent = styled.p`
  max-width: 40rem;
  font-family: 'Brother 1816';
  font-size: 1.1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.375rem;
  color: ${colors.darkBlue};
  text-align: center;

  > strong {
    color: ${colors.lightGreen};
  }
`

const DailyQuestionAnswer = styled.p`
  max-width: 40rem;
  margin: 0 0 2rem;
  font-family: 'Brother 1816';
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  color: ${colors.darkBlue};
  text-align: center;

  > strong {
    font-weight: 500;
    color: ${colors.lightGreen};
  }
`

const StyledIcon = styled(Icon)`
  && {
    position: absolute;
    top: -45px;
    right: -120px;
    padding: 1.8125rem;
    cursor: pointer;
    background-color: ${colors.lightGreen};
    border-radius: 100%;

    @media (width <= 1090px) {
      top: 0;
      right: 0;
    }

    @media (width <= 550px) {
      padding: 0.5rem;
      margin-right: 0.5rem;
    }
  }
`

const SubscribeWording = styled.div `
  margin-bottom: 2rem;
`

const StyledButton = styled.span `
  color: ${colors.lightGreen};
  border-bottom: 1px solid ${colors.lightGreen};
`

export default ModalDailyQuestion
