import { FormElement, Icon } from 'components/atoms'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'

const onClick = id => () => {
  document.getElementById(id).click()
}

const CheckBox = ({
  checked,
  error,
  disableError,
  id,
  name,
  onChange,
  label,
  ...props
}) => (
  <div>
    { disableError
      ? null
      : (
          <FormElement
            error={ error }
            label={ '' }
            name={ name }
          />
        )}
    <InlineWrapper>
      <input
        hidden
        id={ id }
        type="checkbox"
        onChange={ e => onChange(e.target.checked, name) }
      />
      <label
        htmlFor={ id }
      >
        {label}
      </label>
      <div
        onClick={ onClick(id) }
        { ...props }
      >
        <CheckBoxWrapper $error={ error }>
          { checked
            ? (
                <Icon
                  name="check"
                  size={ 8 }
                  stroke={ colors.midnightBlue }
                  strokeWidth={ 10 }
                />
              )
            :
            null }
        </CheckBoxWrapper>
      </div>
    </InlineWrapper>
  </div>
)

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disableError: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

CheckBox.defaultProps = {
  disableError: false,
  error: null,
}

const InlineWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  float: left;
  height: 1.25rem;

  label {
    display: none;
  }
`

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 0.5rem;
  height: 0.5rem;
  padding: 5px;
  cursor: pointer;
  border:
    ${({ $error }) => `1px solid ${typeof $error === 'string'
      ? colors.pink
      : colors.midnightBlue}`};
  border-radius: 2px;
`

export default CheckBox
