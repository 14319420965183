import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'

import Badge from './Badge'

const dateFormat = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}

const TeamList = ({ members }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Fonction</th>
          <th>Badge</th>
          <th>Dernier quiz fait&#42;</th>
          <th>Nb quiz fait(s)&#42;&#42;</th>
        </tr>
      </thead>
      <tbody>
        {members.map(member => (
          <tr key={ member.id }>
            <td>{ member.lastname }</td>
            <td>{ member.firstname }</td>
            <td>{ member.job }</td>
            <td><Badge type={ member.monthlyStatistic.badge } /></td>
            <td>
              { member.monthlyStatistic.lastQuizDate === null
                ? 'NC'
                : new Date(Date.parse(member.monthlyStatistic.lastQuizDate)).toLocaleDateString('fr-FR', dateFormat)
              }
            </td>
            <td>
              { `${member.monthlyStatistic.totalQuizDone} / ${member.monthlyStatistic.totalQuizUnlocked}` }
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

TeamList.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
}

const Table = styled.table`
  width: 100%;
  margin-bottom: 0.875rem;
  border-top: solid 2px ${colors.lightGray};
  border-bottom: solid 2px ${colors.lightGray};

  > thead {
    text-align: left;

    th {
      padding: 1rem 0;
      font-family: Montserrat;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1rem;
      color: ${colors.blueGray};
      text-transform: uppercase;
      opacity: 0.5;
    }
  }

  > tbody {
    td {
      padding-bottom: 1.2rem;
      font-family: 'Brother 1816';
      font-size: 0.875rem;
      color: ${colors.midnightBlue};
    }
  }
`

export default TeamList
