import { useMutation, useQuery } from '@apollo/client'
import ModalThreeDS from 'components/ModalThreeDS'
import { DEFAULT_COUNTRY } from 'constants/countries'
import { CartContext } from 'context/Cart'
import { REGISTER_PAYMENT_METHOD, SUBSCRIBE_STRIPE, SUBSCRIBE_TEAM_STRIPE, UPGRADE_SUBSCRIPTION_STRIPE } from 'graphql/mutations/payment'
import { AVAILABLE_PLANS } from 'graphql/queries/plan'
import { BILLING_ADDRESS, PAYMENT_METHOD, SUBSCRIPTION } from 'graphql/queries/user'
import useGraphQLErrors from 'hooks/useGraphQLErrors'
import useThreeDSecure from 'hooks/useThreeDSecure'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  type MySubscriptionQuery,
  type RegisterPaymentMethodMutation,
  type SubscribeStripeMutation,
  type SubscribeStripeMutationVariables,
  type SubscribeTeamStripeMutation,
  type SubscribeTeamStripeMutationVariables,
  type UpgradeSubscriptionStripeMutation,
  type UpgradeSubscriptionStripeMutationVariables,
} from '@/types/graphql'

import { logError } from '../../logService'
import { PAYMENT_SUCCESSFUL_PATH } from '../Router'
import AddressForm, { type AdressFormType } from './AddressForm'
import BreadCrumbs from './BreadCrumbs'
import { type StripePaymentMethodFormType } from './PaymentMethodForm'
import PaymentMethodForm from './PaymentMethodForm'
import PharmacyForm, { type PharmacyFormType } from './PharmacyForm'

export interface CheckoutFunnelFormType extends PharmacyFormType, AdressFormType, StripePaymentMethodFormType {}

enum formStep {
  PHARMACY = 'PHARMACIE',
  ADDRESS = 'ADRESSE',
  PAYMENT = 'PAIEMENT',
}

export const errorMessages = {
  ERROR_PAYMENT_METHOD_REFUSED: 'Une erreur est survenue lors de la validation de votre moyen de paiement',
  ERROR_GENERIC: `Votre paiement n'a pas été validé, merci de contacter ${process.env.REACT_APP_CONTACT_ADDRESS}`,
}

const CheckoutFunnel = () => {
  const { handleGraphQLErrors } = useGraphQLErrors()
  const navigate = useNavigate()
  const { cart } = useContext(CartContext)
  const [currentPage, setCurrentPage] = useState(0)
  const [form, setForm] = useState<CheckoutFunnelFormType>({
    pharmacyName: '',
    pharmacyZipCode: '',
    pharmacyOrganization: '',
    address: '',
    additionalAddress: '',
    addressZipCode: '',
    city: '',
    country: DEFAULT_COUNTRY,
    paymentMethod: '',
  })
  const mySubscriptionQuery = useQuery<MySubscriptionQuery>(SUBSCRIPTION)
  const mySubscriptionId = useMemo(() => mySubscriptionQuery?.data?.me?.subscription?.id, [mySubscriptionQuery])
  const isTeamSubscription = useMemo(() => cart?.plan?.metadata?.threshold > 1, [cart])
  const formSet = useMemo(() => {
    if (isTeamSubscription) {
      return [formStep.PHARMACY, formStep.ADDRESS, formStep.PAYMENT]
    }

    return [formStep.ADDRESS, formStep.PAYMENT]
  }, [isTeamSubscription])
  const currentFormStep = useMemo(() => formSet[currentPage], [currentPage, formSet])

  const [registerPaymentMethod] = useMutation<RegisterPaymentMethodMutation>(REGISTER_PAYMENT_METHOD)
  const [subscribeStripe] = useMutation<SubscribeStripeMutation>(SUBSCRIBE_STRIPE)
  const [subscribeTeamStripe] = useMutation<SubscribeTeamStripeMutation>(SUBSCRIBE_TEAM_STRIPE)
  const [upgradeSubscription] = useMutation<UpgradeSubscriptionStripeMutation>(UPGRADE_SUBSCRIPTION_STRIPE)

  /**
   * Subscribe to solo subscription
   */
  const handleSubscribeStripe = useCallback(async (variables: SubscribeStripeMutationVariables) => {
    await subscribeStripe({
      variables,
      refetchQueries: [
        { query: SUBSCRIPTION },
        { query: AVAILABLE_PLANS },
        { query: BILLING_ADDRESS },
      ],
      awaitRefetchQueries: true,
    })
  }, [subscribeStripe])

  /**
   * Subscribe to team subscription
   */
  const handleSubscribeTeamStripe = useCallback(async (variables: SubscribeTeamStripeMutationVariables) => {
    await subscribeTeamStripe({
      variables,
      refetchQueries: [
        { query: SUBSCRIPTION },
        { query: AVAILABLE_PLANS },
        { query: BILLING_ADDRESS },
      ],
      awaitRefetchQueries: true,
    })
  }, [subscribeTeamStripe])

  /**
   * Upgrade subscription
   */
  const handleUpgradeSubscription = useCallback(async (variables: UpgradeSubscriptionStripeMutationVariables) => {
    await upgradeSubscription({
      variables,
      refetchQueries: [
        { query: SUBSCRIPTION },
        { query: AVAILABLE_PLANS },
        { query: BILLING_ADDRESS },
      ],
      awaitRefetchQueries: true,
    })
  }, [upgradeSubscription])

  const subscribe = useCallback(async () => {
    if (mySubscriptionId != null) {
      await handleUpgradeSubscription({
        subscriptionId: mySubscriptionId,
        planId: cart.plan.id,
        quantity: Number.parseInt(cart.licencesCountValue),
        pharmacy: {
          name: form.pharmacyName,
          zipCode: form.pharmacyZipCode,
          organization: form.pharmacyOrganization,
        },
      })

      navigate(PAYMENT_SUCCESSFUL_PATH)

      return
    }

    if (isTeamSubscription) {
      await handleSubscribeTeamStripe({
        planId: cart.plan.id,
        pharmacy: {
          name: form.pharmacyName,
          zipCode: form.pharmacyZipCode,
          organization: form.pharmacyOrganization,
        },
        quantity: Number.parseInt(cart.licencesCountValue),
      })

      navigate(PAYMENT_SUCCESSFUL_PATH)

      return
    }

    await handleSubscribeStripe({
      planId: cart.plan.id,
    })

    navigate(PAYMENT_SUCCESSFUL_PATH)
  }, [
    cart,
    form,
    mySubscriptionId,
    isTeamSubscription,
    handleSubscribeStripe,
    handleSubscribeTeamStripe,
    handleUpgradeSubscription,
    navigate,
  ])

  const { handle3DS } = useThreeDSecure(subscribe)

  const submitCheckoutFunnel = useCallback(async (newForm: CheckoutFunnelFormType) => {
    const {
      address,
      additionalAddress,
      addressZipCode,
      city,
      country,
      paymentMethod,
    } = newForm

    const addressProperty = isTeamSubscription
      ? 'billingAddress'
      : 'address'

    try {
      // Register payment method and get 3DS redirect url if needed
      const { data } = await registerPaymentMethod({
        variables: {
          [addressProperty]: {
            address: address,
            address2: additionalAddress,
            zipCode: addressZipCode,
            city: city,
            country: country,
          },
          paymentMethodId: paymentMethod,
        },
        refetchQueries: [
          {
            query: SUBSCRIPTION,
          },
          {
            query: PAYMENT_METHOD,
          },
        ],
        awaitRefetchQueries: true,
      })

      if (data?.registerPaymentMethod?.threeDSRedirectUrl != null) {
        handle3DS(data.registerPaymentMethod.threeDSRedirectUrl)

        return
      }

      subscribe()
    } catch (error) {
      handleGraphQLErrors(
        error,
        errorMessages,
        errorMessages.ERROR_GENERIC,
      )
      logError(error)
    }
  }, [
    isTeamSubscription,
    handleGraphQLErrors,
    handle3DS,
    registerPaymentMethod,
    subscribe,
  ])

  /**
   * Handle each checkoutfunnel form pages validation
   */
  const handleFormValidated = useCallback(async (formPart: PharmacyFormType | AdressFormType | StripePaymentMethodFormType) => {
    const newForm = {
      ...form,
      ...formPart,
    }

    setForm(newForm)

    if (currentPage < formSet.length - 1) {
      setCurrentPage(currentPage + 1)

      return
    }

    // If we are on the last page, submit the checkout funnel
    await submitCheckoutFunnel(newForm)
  }, [currentPage, form, formSet, submitCheckoutFunnel])

  const CurrentForm = useMemo(() => {
    switch (currentFormStep) {

      case formStep.PHARMACY:
        return <PharmacyForm onFormValidated={ handleFormValidated } />
      case formStep.ADDRESS:
        return <AddressForm onFormValidated={ handleFormValidated } />
      case formStep.PAYMENT:
        return (
          <PaymentMethodForm
            onFormValidated={ handleFormValidated }
          />
        )
      default:
        return <PharmacyForm onFormValidated={ handleFormValidated } />
    }
  }, [
    currentFormStep,
    handleFormValidated,
  ])

  return (
    <>
      <ModalThreeDS />
      <Wrapper>
        <BreadCrumbs
          crumbs={ formSet }
          currentCrumbIndex={ currentPage }
        />
        <FormWrapper>
          { CurrentForm }
        </FormWrapper>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FormWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 2.5rem 5rem 1.125rem;

  @media (width <= 690px) {
    margin: 2.5rem 1rem 1.125rem;
  }
`

export default CheckoutFunnel
