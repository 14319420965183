import { useQuery } from '@apollo/client'
import { Button } from 'components/atoms'
import {
  CurrentSubscription,
  Subscriptions,
} from 'components/me/upgradeSubscription'
import { TEAM_SUBSCRIPTION_ANNUAL } from 'constants/subscription'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from 'theme'
import { checkOwner } from 'utils/subscription'

const UpgradeSubscription = () => {
  const userQuery = useQuery(SUBSCRIPTION)
  const navigate = useNavigate()

  useEffect(() => {
    const me = userQuery.data?.me
    const subscription = me?.subscription

    if (subscription && subscription.platform !== 'stripe') {
      navigate('/')
    }
  }, [userQuery, navigate])

  if (userQuery.loading || !userQuery.data?.me) {
    return null
  }

  const { me } = userQuery.data
  const { user, plan } = me.subscription
  const isOwner = checkOwner(me.id, user.id)

  return (
    <>
      <CurrentSubscription isOwner={ isOwner } />
      {plan.id !== TEAM_SUBSCRIPTION_ANNUAL ? (
        <Subscriptions isOwner
          { ...isOwner }
        />
      )
        : null
      }
      <CancelButton
        href="/me/subscription"
        variant="tertiary"
      >
        Annuler
      </CancelButton>
    </>
  )
}

const CancelButton = styled(Button)`
  && {
    justify-content: center;
    color: ${colors.lightGreen};
  }
`

export default UpgradeSubscription
