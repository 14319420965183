import { Icon } from 'components/atoms'
import { DrawerContext } from 'context/Drawer'
import { rgba } from 'polished'
import { useContext } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

import DrawerContent from './DrawerContent'

const SubscriptionDrawer = () => {
  const { state, actions } = useContext(DrawerContext)
  const {
    isOpen,
    plan,
  } = state
  const { toggleDrawer } = actions

  if (plan === null) {
    return null
  }

  const hasBanner = plan.discount !== null

  if (!isOpen) {
    return null
  }

  return (
    <Wrapper $isOpen={ isOpen }>
      <Overlay $isOpen={ isOpen }>
        <DrawerWrapper $isOpen={ isOpen }>
          <IconWrapper $isOpen={ isOpen }>
            <StyledIcon
              fill={ colors.white }
              name="close"
              size="1.7rem"
              onClick={ toggleDrawer }
            />
          </IconWrapper>

          { hasBanner
            ? (
                <DiscountBannerWrapper $isOpen={ isOpen }>
                  <DrawerContent plan={ plan } />
                </DiscountBannerWrapper>
              )
            : (
                <Drawer isOpen={ isOpen }>
                  <DrawerContent plan={ plan } />
                </Drawer>
              )}

        </DrawerWrapper>
      </Overlay>
    </Wrapper>
  )
}

const drawerToggleDuration = 400

const drawerWidth = 26

const Wrapper = styled.div`
  position: fixed;
  z-index: 11;
  display: flex;
  flex-shrink: 0;
  ${({ $isOpen }) => !$isOpen ? `
    visibility: hidden;
    transition: visibility 200ms ease-in-out;
    transition-delay: ${drawerToggleDuration}ms;
  ` : ''}
  width: 100%;
  height: 100%;
`

const Overlay = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  background-color: ${rgba(colors.midnightBlue, 0)};
  transition: background-color 400ms ease-in-out;
  transition-delay: ${drawerToggleDuration / 2}ms;
  ${({ $isOpen }) => $isOpen && `
    background-color: ${rgba(colors.midnightBlue, 0.4)};
  `};
`

const DiscountBannerWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  width: ${drawerWidth}rem;
  height: 100%;
  padding-left: 2rem;
  overflow: auto;

  @media (width <= 580px) {
    padding: 0;
  }
`

const DrawerWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 100%;
  ${({ $isOpen }) => !$isOpen && `
    transform: translateX(${drawerWidth}rem);
  `};
  transition: transform ${drawerToggleDuration}ms ease-in-out;
`

const IconWrapper = styled.div`
  ${({ $isOpen }) => !$isOpen ? 'opacity: 0;' : ''}
  transition: opacity 150ms ease-in-out;
  transition-delay: ${drawerToggleDuration / 1.5}ms;
`

const StyledIcon = styled(Icon)`
  && {
    padding: 1.8125rem;
    margin-right: 2rem;
    cursor: pointer;
    background-color: ${colors.lightGreen};
    border-radius: 100%;

    @media (width <= 550px) {
      padding: 0.5rem;
      margin-right: 0.5rem;
    }
  }
`

const Drawer = styled.nav`
  display: flex;
  flex-direction: column;
  width: ${drawerWidth}rem;
  height: 100%;
  overflow: auto;
  background-color: ${colors.white};
  box-shadow: 0 3px 24px 0 rgb(0 0 0 / 12%);
`

export default SubscriptionDrawer
