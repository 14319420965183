import { useQuery } from '@apollo/client'
import {
  Button,
  FormInput,
  FormSelect,
} from 'components/atoms'
import Form from 'components/atoms/Form'
import { InputType } from 'components/atoms/Input'
import { countries as countriesList } from 'constants/countries'
import { ALL_JOBS } from 'graphql/queries/jobs'
import type React from 'react'
import { type FC } from 'react'
import { useMemo } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

import { type AllJobsQuery } from '@/types/graphql'

const countriesOptions = countriesList.map(country => ({
  label: country,
  value: country,
}))

type Props = {
  editionMode: boolean
  errors: Record<string, string>
  form: {
    lastname: string
    firstname: string
    username: string
    birthdate: string
    email: string
    job: string
    rpps: string
    address: string
    address2: string
    city: string
    zipCode: string
    country: string
  }
  onChange: (value: string, field: string) => void
  onCancel: () => void
  onSubmit: (e: React.FormEvent<HTMLFormElement>, form: Record<string, string>) => void
}

const ProfileForm: FC<Props> = ({
  editionMode,
  errors,
  form,
  onChange,
  onCancel,
  onSubmit,
}) => {
  const {
    lastname,
    firstname,
    username,
    birthdate,
    email,
    job,
    rpps,
    address,
    address2,
    city,
    zipCode,
    country,
  } = form

  const allJobs = useQuery<AllJobsQuery>(ALL_JOBS)

  const jobsOptions = useMemo(() => {
    return allJobs.data?.allJobs?.map(item => ({ label: item.title, value: item.title })) ?? []
  }, [allJobs.data])

  const selectedJob = useMemo(() => {
    return jobsOptions.find(jobOption => jobOption.value === job)
  }, [job, jobsOptions])

  const selectedCountry = useMemo(() => {
    return countriesOptions.find(countryOption => countryOption.value === country)
  }, [country])

  const birthdateValue = useMemo(() => {
    return birthdate ? birthdate?.split('T')[0] : null
  }, [birthdate])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    onSubmit(e, form)
  }

  return (
    <StyledForm
      noValidate
      onSubmit={ handleSubmit }
    >
      <FormInput
        error={ errors.lastname }
        isRequired
        label="Nom"
        name="lastname"
        type={ InputType.TEXT }
        value={ lastname }
        onChangeText={ onChange }
      />
      <FormInput
        error={ errors.firstname }
        isRequired
        label="Prénom"
        name="firstname"
        type={ InputType.TEXT }
        value={ firstname }
        onChangeText={ onChange }
      />
      <FormInput
        error={ errors.username }
        isRequired
        label="Pseudo"
        name="username"
        type={ InputType.TEXT }
        value={ username }
        onChangeText={ onChange }
      />
      <FormInput
        error={ errors.birthdate }
        label="Date de naissance"
        name="birthdate"
        type={ InputType.DATE }
        value={ birthdateValue }
        max={ new Date().toISOString().split('T')[0] }
        onChangeText={ onChange }
      />
      <InformativeText>
        {'Votre date de naissance est nécessaire si vous souhaitez obtenir une attestation DPC.'}
      </InformativeText>
      <FormInput
        disabled={ true }
        error={ errors.email }
        isRequired
        label="Email"
        name="email"
        type={ InputType.EMAIL }
        value={ email }
        onChangeText={ onChange }
      />
      <FormInput
        error={ errors.address }
        label="Adresse"
        name="address"
        type={ InputType.TEXT }
        value={ address }
        onChangeText={ onChange }
      />
      <FormInput
        error={ errors.address2 }
        label="Complément d'adresse"
        name="address2"
        type={ InputType.TEXT }
        value={ address2 }
        onChangeText={ onChange }
      />
      <FormInput
        error={ errors.city }
        label="Ville"
        name="city"
        type={ InputType.TEXT }
        value={ city }
        onChangeText={ onChange }
      />
      <Row>
        <InputWrapper>
          <FormInput
            error={ errors.zipCode }
            label="Code postal"
            name="zipCode"
            type={ InputType.TEXT }
            value={ zipCode }
            onChangeText={ onChange }
          />
        </InputWrapper>
        <InputWrapper>
          <FormSelect
            error={ errors.country }
            label="Pays"
            name="country"
            options={ countriesOptions }
            selectedOption={ selectedCountry }
            onChange={ onChange }
          />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <FormSelect
            error={ errors.job }
            isRequired
            label="Profession"
            name="job"
            options={ jobsOptions }
            selectedOption={ selectedJob }
            onChange={ onChange }
          />
        </InputWrapper>
      </Row>
      <FormInput
        error={ errors.rpps }
        label="N° RPPS"
        maxLength={ 11 }
        name="rpps"
        type={ InputType.NUMBER }
        value={ rpps }
        onChangeText={ onChange }
      />
      { editionMode
        && (
          <EditionButtons>
            <CancelEdition
              size="small"
              variant="tertiary"
              onClick={ onCancel }
            >
              Annuler
            </CancelEdition>
            <ConfirmEdition
              size="small"
              type="submit"
              variant="primary"
            >
              Valider
            </ConfirmEdition>
          </EditionButtons>
        )}
    </StyledForm>
  )
}

const StyledForm = styled(Form)`
  margin-bottom: 2.4375rem;
`

const Row = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;

  @media (width <= 1050px) {
    flex-wrap: wrap;
  }

  & > div:last-child {
    margin-right: 0;
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 1.3125rem;
`

const EditionButtons = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-top: 2rem;
`

const CancelEdition = styled(Button)`
  && {
    margin-right: 1rem;
  }
`

const ConfirmEdition = styled(Button)`
  && {
    padding: 0.83rem 1.5rem;
    background-color: ${colors.lightGreen};
    background-image: none;
    border-radius: 0;
    box-shadow: none;
  }
`

const InformativeText = styled.p`
  margin: 0 0 0.375rem;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1rem;
  color: ${colors.midnightBlue};
`

export default ProfileForm
