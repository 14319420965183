import { Button, FormPasswordInput } from 'components/atoms'
import Form from 'components/atoms/Form'
import styled from 'styled-components'
import { colors } from 'theme'

interface Props {
  errors: Record<string, string | undefined>
  form: {
    password: string
    secondPassword: string
  }
  onChange: (value: string, name: string) => void
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const ResetPassword = ({
  errors,
  form,
  onChange,
  onSubmit,
}: Props) => {
  const {
    password,
    secondPassword,
  } = form

  return (
    <Wrapper>
      <Title>
        Modifier votre mot de passe
      </Title>
      <Form
        id="resetPassword"
        noValidate
        onSubmit={ onSubmit }
      >
        <FormPasswordInput
          error={ errors.password }
          isRequired
          label="Nouveau mot de passe"
          name="password"
          value={ password }
          onChangeText={ onChange }
        />
        <FormPasswordInput
          error={ errors.secondPassword }
          isRequired
          label="Confirmer votre nouveau mot de passe"
          name="secondPassword"
          value={ secondPassword }
          onChangeText={ onChange }
        />

        <ResetPasswordButton
          form="resetPassword"
          size="large"
          type="submit"
          variant="primary"
        >
          Valider votre nouveau mot de passe
        </ResetPasswordButton>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 900;
  line-height: 2.7rem;
  color: ${colors.midnightBlue};
`

const ResetPasswordButton = styled(Button)`
  margin: 2rem 0 14rem;
`

export default ResetPassword
