import { useQuery } from '@apollo/client'
import client from 'apolloClient'
import { SUBSCRIPTION } from 'graphql/queries/user'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import { createRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from 'theme'

const getMenuItems = (navigate, userQuery) => {
  const items = [
    {
      text: 'Mon profil',
      onClick: () => navigate('/me/profile'),
    },
    {
      text: 'Mot de passe',
      onClick: () => navigate('/me/change-password'),
    },
  ]

  if (userQuery?.data?.me?.subscription != null) {
    if (userQuery.data.me.subscription.plan.isTeam && (userQuery.data.me.id === userQuery.data.me.subscription.user.id)) {
      items.push(
        {
          text: 'Statistiques',
          onClick: () => navigate('/me/my-team'),
        },
      )
    }

    items.push(
      {
        text: 'Mon abonnement',
        onClick: () => navigate('/me/subscription'),
      },
    )
  }

  return [
    ...items,
    {
      text: 'Se déconnecter',
      onClick: e => {
        e.preventDefault()
        Cookies.remove('authToken')
        navigate('/')
        setTimeout(client.resetStore, 100)
      },
    },
  ]
}

const MenuControls = ({
  open,
  handleToggle,
}) => {
  const navigate = useNavigate()
  const userQuery = useQuery(SUBSCRIPTION)
  const [menuRef] = useState(createRef())

  useEffect(() => {
    if (open) {
      menuRef.current.focus()
    }
  })

  return (
    <Wrapper>
      <Dots>
        <Circle />
        <Circle />
        <Circle />
      </Dots>
      { open
        ? (
            <Menu
              ref={ menuRef }
              tabIndex="0"
              onBlur={ handleToggle }
            >
              { getMenuItems(navigate, userQuery).map(({
                text,
                onClick,
              }, index) => (
                <Item
                  key={ index }
                  onClick={ onClick }
                >
                  <ItemText>
                    {text}
                  </ItemText>
                </Item>
              ),
              )}
            </Menu>
          )
        : null }
    </Wrapper>
  )
}

MenuControls.defaultProps = {
  handleToggle: null,
}

MenuControls.propTypes = {
  handleToggle: PropTypes.func,
  open: PropTypes.bool.isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`

const Dots = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  cursor: pointer;
`

const circleSize = '0.3125rem'

const Circle = styled.div`
  width: ${circleSize};
  height: ${circleSize};
  margin-bottom: 0.206rem;
  background-color: ${colors.lightGreen};
  border-radius: 100%;
`

const Menu = styled.ul`
  position: absolute;
  top: 100%;
  right: 1rem;
  z-index: 2;
  box-sizing: border-box;
  width: max-content;
  padding: 1.375rem;
  padding-bottom: 0.875rem;
  background-color: ${colors.white};
  border: solid 1px rgb(22 50 98 / 8%);
  border-radius: 4px;
  box-shadow: 8px 2px 40px 0 rgb(0 0 0 / 18%);
`

const Item = styled.li`
  margin-bottom: 0.5rem;
  list-style-type: none;
  cursor: pointer;
`

const ItemText = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  user-select: none;
`

export default MenuControls
