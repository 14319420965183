import { useQuery } from '@apollo/client'
import { logoText } from 'assets/svgs'
import { Button } from 'components/atoms'
import { USER } from 'graphql/queries/user'
import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { colors, sizes } from 'theme'

import { ModalContext } from '../../../context/Modal'
import { getDiscountModalData, hasModal, isUbipharm } from '../../../utils/origin'
import ModalConsortiumOffer from '../../ModalConsortiumOffer'
import Separator from '../Separator'
import StoresButtons from './StoresButtons'

const PresentationHeader = () => {
  const { actions: { setModal } } = useContext(ModalContext)
  const userQuery = useQuery(USER)
  const isLogged = Boolean(userQuery?.data?.me)

  useEffect(() => {
    hasModal() &&
    setModal({
      ...getDiscountModalData(),
      validate: {
        isLogged: isLogged,
        button: {
          text: isUbipharm() ? 'Formulaire de contact' : isLogged ? 'Je m\'abonne' : 'Je m\'inscris',
          handleClick: () => window.location.href = isLogged || isUbipharm() ? '#subscriptions' : '/register',
        },
        signInPrompt: {
          question: 'Vous avez déjà un compte ?',
          text: 'Connectez-vous',
          handleClick: () => window.location.href = '/login',
        },
      },
    })
  }, [isLogged]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      { hasModal() && <ModalConsortiumOffer /> }
      <Wrapper>
        <Row>
          <LeftPart>
            <Logo fill={ colors.lightGreen } />
            <Title>
              Entretenir ses connaissances sur les médicaments n’a jamais été aussi
              <TitleStrong>
                {' facile'}
              </TitleStrong>
            </Title>
            <Separator />
            { isLogged
              ? (
                  <ButtonsWrapper>
                    <StoresButtons />
                    <SubscribeButton
                      href="#subscriptions"
                      size="medium"
                      variant="primary"
                    >
                      {'Je m\'abonne'}
                    </SubscribeButton>
                  </ButtonsWrapper>
                )
              : (
                  <>
                    <Disclaimer>
                      {'Défimédoc est réservé aux professionnels de santé. Votre compte doit être créé et vous devez être identifié pour vous abonner à l\'application Défimédoc.'}
                    </Disclaimer>
                    <RegisterButton
                      href="/register"
                      size="medium"
                      variant="primary"
                    >
                      {'Je m\'inscris'}
                    </RegisterButton>
                  </>
                )}
          </LeftPart>
          <AppPreviewContainer>
            <AppPreview
              alt="aperçu application"
              src={ require('assets/images/landing/app1.png') }
            />
            <AwardBadge
              alt="logo prix innovation pharmagora 2024"
              src={ require('assets/images/landing/pharmagora-award-badge.png') }
            />
          </AppPreviewContainer>
        </Row>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${sizes.heroesPaddingTop};
  background-image: url(${require('assets/images/landing/background-top.png')});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
`

const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Row = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0 10% 5rem;

  @media (width <= 1050px) {
    flex-wrap: wrap;
    margin: 0 5% 5rem;
  }
`

const Title = styled.h1`
  color: ${colors.white};

  @media (width <= 1050px) {
    font-size: 2rem;
    line-height: 2.2rem;
  }
`

const TitleStrong = styled.strong`
  color: ${colors.lightGreen};
`

const Disclaimer = styled.span`
  font-family: "Brother 1816";
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.875rem;
  color: ${colors.white};
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;

  &div:first-child {
    margin-right: 0;
  }
`

const RegisterButton = styled(Button)`
  && {
    align-self: flex-start;
    margin-top: 2.5rem;
  }

  margin-bottom: 3rem;

  @media (width <= 1050px) {
    margin-right: auto;
    margin-left: auto;
  }
`

const SubscribeButton = styled(Button)`
  align-self: center;
  margin-top: 2.5rem;
  margin-left: 5rem;

  @media (width <= 1360px) {
    margin-left: 0;
  }
`

const AppPreviewContainer = styled.div`
  position: relative;
  display: flex;
  align-self: flex-end;

  @media (width <= 1050px) {
    margin-right: auto;
    margin-left: auto;
  }
`

const AppPreview = styled.img`
  height: 37.25rem;
`

const AwardBadge = styled.img`
  position: absolute;
  top: -2rem;
  right: -1rem;
`

const Logo = styled(logoText)`
  display: flex;
  height: 3rem;

  @media (width <= 1050px) {
    margin-top: ${sizes.heroesTitleMobileMarginTop};
  }
`

export default PresentationHeader
