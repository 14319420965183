import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'

const DetailsItem = ({ children }) => (
  <Wrapper>
    <Bullet />
    <Text>
      {children}
    </Text>
  </Wrapper>
)

DetailsItem.propTypes = {
  children: PropTypes.node.isRequired,
}

const Wrapper = styled.li`
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
  margin-bottom: 0.6875rem;
  list-style: none;
`

const Bullet = styled.div`
  padding: 0.15rem;
  margin-right: 0.5rem;
  background-color: ${colors.lightGreen};
  border-radius: 100%;
`

const Text = styled.span`
  font-family: Montserrat;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
`

export default DetailsItem
