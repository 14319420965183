import { useQuery } from '@apollo/client'
import { Button } from 'components/atoms'
import PaymentLayout from 'components/layouts/PaymentLayout'
import { RecapItem } from 'components/paymentSuccessful'
import { APPLE_STORE_APP_URL, PLAY_STORE_APP_URL } from 'constants/link'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from 'theme'
import { calculatePrice, format } from 'utils/price'

const PaymentSuccessful = () => {
  const userQuery = useQuery(SUBSCRIPTION, { fetchPolicy: 'network-only' })
  const navigate = useNavigate()

  useEffect(() => {
    if (!userQuery.loading && userQuery.data?.me) {
      if (userQuery.data.me.subscription === null) {
        navigate('/')
      }
    }
  })

  if (userQuery.loading || !userQuery?.data?.me?.subscription) {
    return null
  }

  localStorage.removeItem('cart')

  const { plan, quantity, trialPeriodDays } = userQuery.data.me.subscription
  const hasMultipleLicences = quantity > 1
  const hasTrialPeriod = trialPeriodDays > 0
  const {
    amount,
    interval,
    metadata: {
      discount: planDiscount,
    },
  } = plan

  const { totalTTC } = calculatePrice({
    amount,
    quantity,
    planDiscount,
  })

  const subscriptionSummary = `${hasTrialPeriod ? `Vous bénéficiez d'une période d'essai gratuite de ${trialPeriodDays} jours.` : ''}
    La reconduction automatique de votre abonnement peut être désactivée dans les paramètres de votre abonnement.
    S'il n'est pas désactivé, votre abonnement sera automatiquement renouvelé tous les ${interval === 'month' ? 'mois' : 'ans'} au prix de ${format(totalTTC)} TTC ${!hasMultipleLicences ? ' pour toute votre équipe' : ''}.
    L'abonnement est annulable à tout moment et sans frais au cours de votre période d'essai.
  `

  return (
    <PaymentLayout>
      <TopWrapper>
        <Title>Paiement accepté !</Title>
        <Description>
          {
            `Vous faites maintenant partie de la communauté Défimédoc :)
           Un email de confirmation vient de vous être envoyé.`
          }
        </Description>
        <Description>
          {subscriptionSummary}
        </Description>
      </TopWrapper>
      <Recap>
        <RecapLeft>
          <SubscriptionName>{`Abonnement ${plan.title}`}</SubscriptionName>
          <LicenceCount>{`${quantity} ${hasMultipleLicences ? 'Licences' : 'Licence'}`}</LicenceCount>
          <Subscribe
            href="/me/subscription"
            size="small"
            variant="primary"
          >
            {`${hasMultipleLicences ? 'Enregistrer mon équipe' : 'Gérer l’abonnement'}`}
          </Subscribe>
        </RecapLeft>
        <RecapRight>
          <RecapItem>
            {`Paiement ${interval === 'month' ? 'mensuel' : 'annuel'} de ${format(totalTTC)} TTC`}
          </RecapItem>
          <RecapItem>
            Retrouvez vos factures dans votre espace client
          </RecapItem>
          <RecapItem>
            S’il n’est pas désactivé, votre abonnement se renouvellera automatiquement
          </RecapItem>
        </RecapRight>
      </Recap>
      <NextStep>Prochaine étape</NextStep>
      <DownloadApp>
        {'Télécharger l\'application'}
      </DownloadApp>
      <StoresButtons>
        <AndroidButton
          href={ PLAY_STORE_APP_URL }
          icon="android"
          iconSize="1.5em"
          title="Télécharger"
          variant="primary"
        />
        <AppleButton
          href={ APPLE_STORE_APP_URL }
          icon="apple"
          iconSize="1.5em"
          title="Télécharger"
          variant="primary"
        />
      </StoresButtons>
      <BackToHome href="/">
        {'Retour à l\'accueil'}
      </BackToHome>
    </PaymentLayout>
  )
}

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 2.5rem 6.875rem 10rem 4.375rem;
  background-image: url(${require('assets/images/payment-successful/background.png')});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;

  @media (width <= 670px) {
    padding: 2.5rem 1.875rem 10rem 1.375rem;
  }
`

const Description = styled.p`
  margin: 1rem 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: ${colors.white};
  white-space: pre-line;

  @media (width <= 670px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

const Recap = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: center;
  justify-content: space-between;
  padding: 1.75rem 2.5rem 1.75rem 3.125rem;
  margin: -8rem 6.875rem 3rem;
  background-image: linear-gradient(113deg, ${colors.white}, rgb(255 255 255 / 54%));
  border: solid 0.0625rem rgb(22 50 98 / 8%);
  box-shadow: 0.375rem 0.125rem 2.5rem 0 rgb(6 38 79 / 16%);

  @media (width <= 1020px) {
    flex-wrap: wrap;
    padding: 1.75rem 1.5rem 1.75rem 1.125rem;
    margin: -8rem 1.875rem 3rem;
  }

  @media (width <= 800px) {
    margin: -8rem 1rem 3rem;
  }
`

const RecapLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.2rem 6.25rem 0 0;

  @media (width <= 670px) {
    margin-right: 0;
  }
`

const Title = styled.h1`
  margin-bottom: 0.2rem;
  font-family: 'Brother 1816';
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 900;
  color: ${colors.white};
  white-space: nowrap;

  @media (width <= 670px) {
    font-size: 2.125rem;
  }
`

const SubscriptionName = styled.h3`
  margin: 0 0 0.5rem;
  font-family: 'Brother 1816';
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 900;
`

const LicenceCount = styled.span`
  margin: 0 0 1.3125rem;
  font-family: 'Brother 1816';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.cornflowerBlue};
  text-align: center;
`

const Subscribe = styled(Button)`
  && {
    padding: 0.8rem 2.5rem;
    font-size: 1.5rem;
  }
`

const RecapRight = styled.ul`
  padding: 0;
  margin: 0;

  @media (width <= 1020px) {
    padding-top: 1.5rem;

    & > li {
      display: block;
    }
  }
`

const NextStep = styled.span`
  font-family: 'Brother 1816';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.lightGreen};
  text-align: center;
`

const DownloadApp = styled.h2`
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 900;
  text-align: center;
`

const StoresButtons = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: center;
  margin-bottom: 5rem;
  font-size: 1rem;

  @media (width <= 500px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const buttonpadding = '1.1rem 2.2rem'

const AndroidButton = styled(Button)`
  && {
    padding: ${buttonpadding};
    margin-right: 1.75rem;

    @media (width <= 500px) {
      margin: 0.5rem;
    }
  }
`

const AppleButton = styled(Button)`
  && {
    padding: ${buttonpadding};

    @media (width <= 500px) {
      margin: 0.5rem;
    }
  }
`

const BackToHome = styled(Button)`
  && {
    align-self: center;
    justify-content: center;
    margin-bottom: 5rem;
    font-family: 'Brother 1816';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 900;
    color: ${colors.hanBlue};
  }
`

export default PaymentSuccessful
