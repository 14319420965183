import { useQuery } from '@apollo/client'
import { Button, FormSelect } from 'components/atoms'
import Form from 'components/atoms/Form'
import DiscountBanner from 'components/landing/DiscountBanner'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { rgba } from 'polished'
import type React from 'react'
import { type FC } from 'react'
import { useMemo } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import { isPharmUppUltra } from 'utils/origin'
import { calculatePrice, format } from 'utils/price'

import { type Coupon, type Plan } from '@/types/graphql'

const DrawerBottom: FC<{
  form: {
    licencesCount: number
    coupon: Coupon|null
  }
  plan: Plan
  onChange: (value: string, field: string) => void
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  minLicencesNumber: number
}> = ({
  form,
  plan,
  onChange,
  onSubmit,
  minLicencesNumber,
}) => {
  const {
    licencesCount,
    coupon,
  } = form

  const {
    interval,
    amount,
    metadata: {
      threshold,
      discount: planDiscount,
      infoDrawer,
    },
  } = plan

  const licencesCountValue = threshold > 1 ? licencesCount : 1
  const hasMultipleLicences = threshold > 1
  const hasDiscount = planDiscount !== null && planDiscount?.levels != null

  const { subTotal, tax, totalTTC, hasApplicableDiscountLevel, applicableDiscountLevel, discount } = calculatePrice({
    amount,
    quantity: licencesCountValue,
    planDiscount,
    coupon,
  })

  const userQuery = useQuery(SUBSCRIPTION)
  const isLogged = Boolean(userQuery?.data?.me)
  const subscription = userQuery?.data?.me?.subscription

  const licencesCountOptions = useMemo(() => {
    const options = []

    for (let i = minLicencesNumber; i <= 100; i++) {
      options.push({
        label: `${i} licence${i > 1 ? 's' : ''}`,
        value: i.toString() })
    }

    return options
  }, [minLicencesNumber])

  const selectedLicenceCountOption = useMemo(() => {
    return licencesCountOptions.find(option => Number(option.value) === Number(licencesCount))
  }, [licencesCount, licencesCountOptions])

  const hasAnotherSubscriptionType = useMemo(() =>
    (isLogged && subscription && subscription?.platform !== 'stripe'),
  [isLogged, subscription])

  return (
    <Wrapper>
      {hasDiscount && (
        <DiscountBanner
          description={ planDiscount.description }
          isOnDrawer={ true }
          title={ planDiscount.title }
        />
      )}
      { infoDrawer && (
        <Information>
          { infoDrawer }
        </Information>
      )}
      <Form
        withRequiredFieldInfo={ false }
        onSubmit={ onSubmit }
      >
        {hasMultipleLicences
          ? (
              <FormSelect
                dropdownIndicatorStyle={ dropdownIndicatorStyle }
                inputStyle={ selectInputStyle }
                label="Choisissez le nombre de licences :"
                name="licencesCount"
                options={ licencesCountOptions }
                selectedOption={ selectedLicenceCountOption }
                onChange={ onChange }
              />
            )

          : null}
        <PriceCalculator>
          {
            !isPharmUppUltra()
              ? (
                  <>
                    <Row>
                      <span>{`Prix / ${interval === 'month' ? 'mois' : 'an'}`}</span>
                      <span>{`${format(amount / 100)} HT`}</span>
                    </Row>
                    <Separator />
                    <Row>
                      <span>Nb de licences</span>
                      <span>{licencesCountValue}</span>
                    </Row>
                    <Separator />
                    <Row>
                      <span>Sous-total</span>
                      <span>{`${format(subTotal)} HT`}</span>
                    </Row>
                    <Row>
                      <span>TVA</span>
                      <span>{`${format(tax)}`}</span>
                    </Row>
                    {hasApplicableDiscountLevel && (
                      <>
                        <Separator />
                        <Row $bold>
                          <span>{applicableDiscountLevel?.label}</span>
                          <span>{applicableDiscountLevel?.fixedPrice ? `${format(subTotal)} prix fixe` : `-${applicableDiscountLevel?.percentOff}%`}</span>
                        </Row>
                      </>
                    )}
                    {coupon !== null
                      ? (
                          <Row $discount>
                            <span>Réduction</span>
                            <span>{`-${format(discount)} HT`}</span>
                          </Row>
                        )
                      : null}
                    <Separator />
                  </>
                )
              : null
          }
          <Row $bold>
            <span>{`Total TTC/${plan.interval === 'year' ? 'an' : 'mois'}`}</span>
            <span>
              {`${format(totalTTC)}`}
            </span>
          </Row>
          <Separator />
          {hasAnotherSubscriptionType && (
            <WarningMessage>
              Vous êtes déjà abonné à défimedoc depuis une autre plateforme. Pour souscrire à cette offre, veuillez annuler votre abonnement actuel.
            </WarningMessage>
          )}
          {isLogged
            ? (
                <SubscribeButton
                  disabled={ hasAnotherSubscriptionType }
                  size="small"
                  type="submit"
                  variant="primary"
                  onClick={ onSubmit }
                >
                  {'Je m’abonne'}
                </SubscribeButton>
              )
            : (
                <SubscribeButton
                  href="/login"
                  size="small"
                  variant="primary"
                >
                  {'Me connecter'}
                </SubscribeButton>
              )}
        </PriceCalculator>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  padding: 1.1875rem 2.625rem 1.1875rem 2.3125rem;
  background-color: ${colors.white};

  @media (width <= 550px) {
    padding-top: 0;
  }
`

const PriceCalculator = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1.25rem;
  margin-top: 1.875rem;
  background-color: ${colors.white};
  border: solid 1px ${rgba(colors.darkBlue, 0.08)};
  border-radius: 4px;
  box-shadow: 8px 2px 40px 0 rgb(0 0 0 / 18%);

  @media (width <= 550px) {
    margin-top: 0.5rem;
  }
`

const Row = styled.div<{ $bold?: boolean, $discount?: boolean }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 2.5rem;

  & > span {
    font-family: 'Brother 1816';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: ${({ $bold }) => $bold ? '900' : 'normal'};
    color: ${colors.midnightBlue};
    ${({ $discount }) => $discount && `
      color: ${colors.lightGreen};
      font-weight: bold;
    `};

    &:first-of-type {
      width: 10rem;
    }
  }
`

const taupe = '#dae5f1'

const Separator = styled.div`
  height: 1px;
  background-color: ${taupe};
`

const SubscribeButton = styled(Button)`
  && {
    justify-content: center;
    margin-top: 1rem;

    &:disabled:hover {
      cursor: not-allowed;
    }
  }
`

const WarningMessage = styled.div`
  margin-top: 1rem;
  font-family: 'Brother 1816';
  font-size: small;
  font-style: normal;
  font-weight: bold;
  color: ${colors.red};
`

const selectInputStyle = {
  fontFamily: 'Brother 1816',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 600,
  color: colors.midnightBlue,
  border: `solid 1px ${colors.hanBlue}`,
  borderRadius: '5px',
  boxShadow: '0 4px 8px 0 rgb(0 0 0 / 18%)',
}

const dropdownIndicatorStyle = {
  backgroundColor: colors.hanBlue,
}

const Information = styled.div`
  padding: 0.5rem;
  margin: 0 0 1rem;
  font-family: 'Brother 1816';
  font-style: normal;
  font-weight: 600;
  text-align: center;
  background-color: ${colors.yellow};
`

export default DrawerBottom
