export const formatISODate = isoDate => {
  if (!isoDate) {
    return ''
  }

  const date = new Date(isoDate)

  if (isNaN(date.getTime())) {
    return ''
  }

  return Intl.DateTimeFormat('fr').format(date)
}
