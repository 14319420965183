import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Button } from 'components/atoms'
import Aknowledgement from 'components/forms/Aknowledgement'
import { errorMessages } from 'components/payment/CheckoutFunnel'
import { requireTosValidation } from 'constants/form'
import { CartContext } from 'context/Cart'
import { logError } from 'logService'
import type React from 'react'
import { type FC } from 'react'
import { useCallback, useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { colors } from 'theme'

export enum PaymentMethodType {
  CARD = 'card',
  SEPA = 'sepa',
}

export type StripePaymentMethodFormType = {
  paymentMethod: string
  adress?: string
}

type Props = {
  editMode?: boolean
  onCancel?: () => void
  onFormValidated: (form: StripePaymentMethodFormType) => Promise<void>
}

const PaymentMethodForm: FC<Props> = ({
  onCancel,
  editMode = false,
  onFormValidated,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const { cart } = useContext(CartContext)

  const [acceptedCGV, setAcceptedCGV] = useState(false)
  const [acceptCGVError, setAcceptCGVError] = useState<string|null>(null)
  const [processing, setProcessing] = useState(false)

  const options = {
    paymentMethodOrder: ['sepa_debit', 'card'],
  }

  const isTeamSubscription = useMemo(() => cart?.plan?.metadata?.threshold > 1, [cart])

  const displayGenericError = () => {
    toast.error(errorMessages.ERROR_GENERIC, { position: 'top-center' })
  }

  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!stripe || !elements) {
      displayGenericError()

      return
    }

    if (!acceptedCGV && !editMode) {
      setAcceptCGVError(requireTosValidation)

      return
    } else {
      setAcceptCGVError(null)
    }

    const { error: submitError } = await elements.submit()

    if (submitError) {
      return
    }

    try {
      setProcessing(true)

      const { paymentMethod } = await stripe.createPaymentMethod({
        elements,
      })

      if (null == paymentMethod) {
        toast.error(errorMessages.ERROR_GENERIC, { position: 'top-center' })

        return
      }

      await onFormValidated({ paymentMethod: paymentMethod.id })
    } catch (error) {
      displayGenericError()
      logError(error)
    } finally {
      setProcessing(false)
    }
  }, [
    stripe,
    elements,
    acceptedCGV,
    editMode,
    onFormValidated,
  ])

  return (
    <Form
      data-testid="payment-form"
      id="payment-form"
      onSubmit={ handleSubmit }
    >
      <PaymentElement options={ options } />
      {
        editMode
          ? (
              <EditionButtons>
                <CancelEdition
                  size="small"
                  variant="tertiary"
                  onClick={ onCancel }
                >
                  Annuler
                </CancelEdition>
                <ConfirmEdition
                  disabled={ processing }
                  size="small"
                  type="submit"
                  variant="primary"
                >
                  Valider
                </ConfirmEdition>
              </EditionButtons>
            )
          : (
              <>
                <Aknowledgement
                  aknowledged={ acceptedCGV }
                  error={ acceptCGVError }
                  isTeam={ isTeamSubscription }
                  onChange={ checked => setAcceptedCGV(checked) }
                />
                <ContinueButton
                  disabled={ processing }
                  id="payment-button"
                  size="small"
                  type="submit"
                  variant="primary"
                >
                  Je confirme mon abonnement
                </ContinueButton>
              </>
            )}
    </Form>
  )
}

const CancelEdition = styled(Button)`
  margin-right: 1rem;
`

const ConfirmEdition = styled(Button)`
  padding: 0.83rem 1.5rem;
  background-color: ${colors.lightGreen};
  background-image: none;
  border-radius: 0;
  box-shadow: none;
`

const EditionButtons = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
`

const ContinueButton = styled(Button)`
  align-self: center;
  padding: 0.8rem 2.5rem;
  margin: 2rem 0 1.5rem;
  font-size: 1.5rem;
  border-radius: 0;
`

const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export default PaymentMethodForm
