import { useQuery } from '@apollo/client'
import { Button, FormInput } from 'components/atoms'
import Form from 'components/atoms/Form'
import { InputType } from 'components/atoms/Input'
import SectionMarker from 'components/atoms/SectionMarker'
import { invalidField, mandatoryField } from 'constants/form'
import { USER } from 'graphql/queries/user'
import useForm from 'hooks/useForm'
import { type FC, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import zod from 'zod'

import { type MeQuery } from '@/types/graphql'

const pharmacyFormSchema = zod.object({
  pharmacyName: zod.string().min(1, mandatoryField),
  pharmacyZipCode: zod.string().regex(/^\d{5}$/, invalidField).min(1, mandatoryField),
  pharmacyOrganization: zod.string().nullish(),
})

export type PharmacyFormType = zod.infer<typeof pharmacyFormSchema>

type Props = {
  onFormValidated: (form: PharmacyFormType) => void
}

const IdentifyForm: FC<Props> = ({
  onFormValidated,
}) => {
  const { data: userData, loading } = useQuery<MeQuery>(USER)
  const initialState = useMemo(() => {
    const pharmacy = userData?.me?.pharmacy

    return {
      pharmacyName: pharmacy?.name ?? '',
      pharmacyZipCode: pharmacy?.zipCode ?? '',
      pharmacyOrganization: pharmacy?.organization ?? '',
    }
  }, [userData])

  const [data, errors, handleChange, handleSubmit, setData] = useForm(initialState, pharmacyFormSchema, onFormValidated)

  useEffect(() => {
    setData(initialState)
  }, [initialState, setData])

  if (loading || !userData?.me) {
    return null
  }

  return (
    <Wrapper>
      <Disclaimer>
        Vérifiez les informations de votre pharmacie pour passer à l’étape suivante.
      </Disclaimer>
      <SectionMarker title="votre pharmacie" />
      <Form onSubmit={ handleSubmit }>
        <FormInput
          error={ errors.pharmacyName }
          isRequired
          label="Nom de la pharmacie"
          name="pharmacyName"
          type={ InputType.TEXT }
          value={ data.pharmacyName }
          onChangeText={ handleChange }
        />
        <FormInput
          error={ errors.pharmacyZipCode }
          isRequired
          label="Code postal"
          name="pharmacyZipCode"
          type={ InputType.TEXT }
          value={ data.pharmacyZipCode }
          onChangeText={ handleChange }
        />
        <FormInput
          error={ errors.pharmacyOrganization }
          label="Votre partenaire 'Conditions spéciales d'abonnement Défimédoc' (groupement, grossiste, syndicat...)"
          name="pharmacyOrganization"
          type={ InputType.TEXT }
          value={ data.pharmacyOrganization }
          onChangeText={ handleChange }
        />
        <ContinueButton
          size="small"
          type="submit"
          variant="primary"
        >
          Continuer
        </ContinueButton>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .hidden-label {
    label {
      display: none;
    }
  }
`

const Disclaimer = styled.p`
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  color: ${colors.midnightBlue};
  opacity: 0.5;
`

const ContinueButton = styled(Button)`
  && {
    align-self: center;
    padding: 0.8rem 2.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    border-radius: 0;
  }
`

export default IdentifyForm
