import { format, parseISO } from 'date-fns'
import { fr } from 'date-fns/locale'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'
import { format as formatPrice } from 'utils/price'

const Invoices = ({
  invoices,
}) => (
  <Wrapper>
    <Title>
      MES FACTURES
    </Title>
    <InvoiceList>
      { invoices.map(({
        createdAt,
        amount,
        pdfUrl,
      }, index) => (
        <Invoice key={ index }>
          <Date>
            {format(parseISO(createdAt), 'd MMMM yyyy', { locale: fr })}
          </Date>
          <Cost>
            { `${formatPrice(amount)}` }
          </Cost>
          <Link href={ pdfUrl }>
            PDF
          </Link>
        </Invoice>
      ),
      )}
    </InvoiceList>
  </Wrapper>
)

Invoices.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      pdfUrl: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  margin-bottom: 2rem;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.darkGray};
  opacity: 0.5;
`

const InvoiceList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
`

const Invoice = styled.li`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.5rem;
`

const Date = styled.span`
  font-family: 'Brother 1816';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.midnightBlue};
`

const Cost = styled.span`
  font-family: 'Brother 1816';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.midnightBlue};
`

const Link = styled.a`
  font-family: 'Brother 1816';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
`

export default Invoices
