import { CookieBannerContext } from 'context/CookieBanner'
import { type FC, useContext } from 'react'
import CookieConsent from 'react-cookie-consent'
import styled from 'styled-components'

const CookieBanner: FC = () => {
  const { isVisible, onAccept } = useContext(CookieBannerContext)

  return (
    <CookieConsent
      buttonStyle={ buttonStyle }
      buttonText="J'accepte"
      cookieName="cookie-consent"
      location="bottom"
      style={ {
        backgroundColor: '#032148',
      } }
      visible={ isVisible ? 'show' : 'byCookieValue' }
      onAccept={ onAccept }
    >
      <CookiePresentation>
        Notre site internet utilise uniquement des cookies nécessaires à son bon fonctionnement.
        Ces cookies ne sont pas soumis à votre consentement
        et vous ne pouvez pas vous opposer à leur utilisation.

        Cependant vous pouvez techniquement les bloquer, en utilisant les paramètres
        de votre navigateur, mais votre expérience sur le site peut être dégradée.
        Pour en savoir plus consultez notre&nbsp;
        {' '}
        <a href="/cookie-policy"
          target="blank"
        >
          politique de gestion des cookies.
        </a>
      </CookiePresentation>
    </CookieConsent>
  )
}

const CookiePresentation = styled.p`
  font-family: 'Brother 1816';
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.875rem;
  color: '#fff';

  @media (width <= 1050px) {
    font-size: 0.9rem;
  }

  @media (width <= 700px) {
    font-size: 0.8rem;
  }
`

const buttonStyle = {
  margin: '15px',
  padding: '1rem 3rem',
  fontFamily: 'Montserrat',
  fontSize: '0.8em',
  borderRadius: '4px',
  boxShadow: 'rgba(0,0,0,0.35) 0 2px 5px 0',
  backgroundImage:
    'linear-gradient(to bottom right,rgb(35,188,186),rgb(0,229,166))',
  color: '#fff',
  fontWeight: '800',
  alignSelf: 'center',
}

export default CookieBanner
