import { type FC, type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

type Props = {
  id?: string
  autoComplete?: 'on' | 'off'
  noValidate?: boolean
  withRequiredFieldInfo?: boolean
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

const Form: FC<PropsWithChildren<Props>> = ({
  id,
  withRequiredFieldInfo = true,
  autoComplete,
  noValidate,
  children,
  onSubmit,
}) => (
  <form
    autoComplete={ autoComplete }
    id={ id }
    noValidate={ noValidate }
    onSubmit={ onSubmit }
  >
    { withRequiredFieldInfo && (
      <RequiredFieldInfo>
        Les champs marqués d’un astérisque sont obligatoires
      </RequiredFieldInfo>
    )}
    { children }
  </form>
)

const RequiredFieldInfo = styled.p`
  margin-top: 0;
  margin-bottom: 2rem;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1rem;
  color: ${colors.midnightBlue};
`

export default Form
