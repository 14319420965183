import illustration from 'assets/images/dpc/training-space.svg'
import { Button } from 'components/atoms'
import styled from 'styled-components'
import { sizes } from 'theme'
import { downloadRules } from 'utils/file'

const Hero = () => (
  <Wrapper>
    <h1 className="text-heading">
      Bienvenue dans votre <em>espace formation DPC</em> !
    </h1>
    <p className="text-content">Référent pédagogique : Florence Bontemps, Dr en pharmacie.</p>
    <Button
      variant="secondary"
      onClick={ downloadRules }
    >
      Règlement intérieur
    </Button>
    <img src={ illustration } />
  </Wrapper>
)

const Wrapper = styled.section`
  max-width: 35rem;
  min-height: 28rem;

  img {
    position: absolute;
    top: ${sizes.heroesPaddingTop};
    right: 0;
    z-index: -1;
  }

  @media (width <= 800px) {
    min-height: initial;

    img {
      display: none;
    }
  }
`

export default Hero
