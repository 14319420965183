import { useQuery } from '@apollo/client'
import PaymentLayout from 'components/layouts/PaymentLayout'
import CheckoutFunnel from 'components/payment/CheckoutFunnel'
import ReassuranceItems from 'components/ReassuranceItems'
import { LANDING_PATH } from 'components/Router'
import StripeProvider from 'context/StripeProvider'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { type MySubscriptionQuery } from '@/types/graphql'

const Payment = () => {
  const navigate = useNavigate()
  const userQuery = useQuery<MySubscriptionQuery>(SUBSCRIPTION)

  useEffect(() => {
    const me = userQuery.data?.me
    const subscription = me?.subscription

    if (!userQuery.loading && (!me || (subscription && subscription.platform !== 'stripe'))) {
      navigate(LANDING_PATH)
    }
  }, [userQuery, navigate])

  if (localStorage.getItem('cart') === null) {
    navigate(LANDING_PATH)

    return null
  }

  return (
    <PaymentLayout>
      <CenterWrapper>
        <StripeProvider>
          <CheckoutFunnel />
        </StripeProvider>
      </CenterWrapper>
      <ScaleDown>
        <ReassuranceItems />
      </ScaleDown>
    </PaymentLayout>
  )
}

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 50%;
  border: solid 1px rgb(22 50 98 / 8%);
  box-shadow: 8px 2px 40px 0 rgb(6 38 79 / 16%);

  @media (width <= 1370px) {
    width: 60%;
  }

  @media (width <= 1140px) {
    width: 70%;
  }

  @media (width <= 970px) {
    width: 80%;
  }

  @media (width <= 850px) {
    width: 90%;
  }

  @media (width <= 760px) {
    width: 100%;
    border: none;
  }
`

const ScaleDown = styled.div`
  transform: scale(0.7);
`

export default Payment
