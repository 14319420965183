import { FormElement, Icon } from 'components/atoms'
import Input, { type InputType } from 'components/atoms/Input'
import { useId } from 'react'
import { colors } from 'theme'

type Props<T extends string> = {
  disabled?: boolean
  error?: string
  icon?: string
  label: string
  maxLength?: number
  name: T
  placeholder?: string
  type: InputType
  value?: string | number | null
  max?: string
  isRequired?: boolean
  onChangeText: (value: string, field: T) => void
}

const FormInput = <T extends string>({
  label,
  icon,
  error,
  disabled = false,
  placeholder,
  maxLength,
  name,
  type,
  value,
  max,
  isRequired = false,
  onChangeText,
}: Props<T>) => {
  const id = useId()

  return (
    <FormElement
      error={ error }
      isRequired={ isRequired }
      label={ label }
      name={ id }
    >
      { icon
        ? (
            <div>
              <Icon
                fill={ colors.lightGreen }
                name={ icon }
                size="1em"
              />
              <Input
                disabled={ disabled }
                error={ error }
                id={ id }
                maxLength={ maxLength }
                name={ name }
                placeholder={ placeholder }
                type={ type }
                value={ value }
                onChangeText={ onChangeText }
              />
            </div>
          )
        : (
            <Input
              disabled={ disabled }
              error={ error }
              id={ id }
              maxLength={ maxLength }
              name={ name }
              placeholder={ placeholder }
              type={ type }
              value={ value }
              max={ max }
              onChangeText={ onChangeText }
            />
          )}
    </FormElement>
  )
}

export default FormInput
