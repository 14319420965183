import { FormElement, Icon } from 'components/atoms'
import type React from 'react'
import { useId } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import { truncate } from 'utils/file'

type Props<T extends string> = {
  error?: string | null
  file?: File | null
  label: string
  name: T
  onChange: (file: File | null, field: T) => void
}

const FormFilePicker = <T extends string>({
  label,
  error,
  file,
  name,
  onChange,
}: Props<T>) => {
  const id = useId()

  const handleDelete = () => {
    onChange(null, name)
  }

  return (
    <FormElement
      error={ error }
      label={ label }
      name={ id }
    >
      { file == null
        ? (
            <FilePicker
              $error={ error }
              htmlFor={ id }
            >
              Ajouter un fichier
              <FileSize>Maximum 2 Mo</FileSize>
            </FilePicker>
          )
        : (
            <>
              <EditWrapper>
                <File>
                  <FileName>
                    { file && truncate(file.name, 10)}
                  </FileName>
                  <Delete
                    fill={ colors.darkBlue }
                    name="delete"
                    size={ 17 }
                    onClick={ handleDelete }
                  />
                </File>
                <Edit htmlFor={ id }>
                  Modifier …
                </Edit>
              </EditWrapper>
            </>
          )}
      <input
        accept="application/pdf, image/png, image/jpg"
        hidden
        id={ id }
        name="file"
        type="file"
        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
          const files = e.target.files

          if (files && files.length > 0) {
            onChange(files[0], name)
          }
        } }
      />
    </FormElement>
  )
}

type FilePickerProps = {
  $error?: string | null
}

const FilePicker = styled.label<FilePickerProps>`
  max-width: 8rem;
  padding: 0.9rem 0.6rem;
  color:
    ${({ $error }) => `${typeof $error === 'string'
      ? colors.pink
      : colors.midnightBlue}`};
  text-align: center;
  cursor: pointer;
  border:
    ${({ $error }) => `1px solid ${typeof $error === 'string'
      ? colors.pink
      : colors.midnightBlue}`};
  border-radius: 4px;
`

const EditWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`

const File = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border: 1px solid ${colors.midnightBlue};
  border-radius: 20px;
`

const FileName = styled.span`
  margin-right: 0.6rem;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.midnightBlue};
`

const Delete = styled(Icon)`
  cursor: pointer;
`

const Edit = styled.label`
  margin-bottom: 0;
  font-family: 'Brother 1816';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
`

const FileSize = styled.div`
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1rem;
`

export default FormFilePicker
