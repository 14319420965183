import { Icon } from 'components/atoms'
import PropTypes from 'prop-types'
import { colors } from 'theme'

const CloseButton = ({ closeToast }) => (
  <Icon
    fill={ colors.white }
    name="close"
    onClick={ closeToast }
  />
)

CloseButton.propTypes = {
  closeToast: PropTypes.func,
}

CloseButton.defaultProps = {
  closeToast: () => {},
}

export default CloseButton
