import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { colors } from 'theme'

const GlobalStyle = createGlobalStyle`
  ${normalize}

  img {
    user-select: none;
  }

  blockquote {
    margin: 0;
  }

  button {
    all: unset;
    cursor: pointer;
    user-select: none;
  }

  html, body, #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  h1 {
    font-family: 'Brother 1816';
    font-size: 3.13rem;
    font-style: normal;
    font-weight: 900;
    line-height: 3.75rem;
  }

  p {
    font-family: Montserrat;
    font-size: 1.38rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2.25rem;
  }

  a {
    color: ${colors.lightGreen};
    text-decoration: none;
    user-select: none;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-family: Montserrat;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: ${colors.darkGray};
  }

  input {
    all: initial;
    display: flex;
    flex: 1;
    flex-shrink: 0;
    padding-left: 1.125rem;
    margin-bottom: 1rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    line-height: 2.8rem;
    background-color: #fff;
    border-top: 1px solid ${colors.lightGreen};
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 18%);

    &::placeholder {
      font-family: Montserrat;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: bold;
      color: ${colors.mediumGray};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow:
        0 0 0 1000px #fff inset,
        0 4px 8px 0 rgb(0 0 0 / 18%);
    }

    &:disabled {
      color: #9a9a9a;
      background-color: #f5f5f5;
    }
  }

  em {
    font-style: normal;
  }

  .text-heading {
    font-family: 'Brother 1816';
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 55px;
    color: ${colors.darkBlue};
    text-align: center;
  }

  .text-subheading {
    font-family: 'Brother 1816';
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 800;
    line-height: 46px;
    color: ${colors.purple};
  }

  .text-content {
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .text-description {
    font-family: 'Brother 1816';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
  }

  .Toastify__toast-container {
    top: 0;
    width: 100%;
    padding: 0;
    margin-left: 0;
  }

  .Toastify__toast {
    align-items: center;
    min-height: 0;
    padding: 0.625rem;
  }

  .Toastify__toast-body {
    flex-grow: 1;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    color: ${colors.white};
    text-align: center;
  }

  .Toastify__toast--success {
    background-color: ${colors.lightGreen};
  }

  .Toastify__toast--error {
    background-color: ${colors.pink};
  }
`

export default GlobalStyle
