import { useMutation, useQuery } from '@apollo/client'
import ModalThreeDS from 'components/ModalThreeDS'
import PaymentMethodForm, { type StripePaymentMethodFormType } from 'components/payment/PaymentMethodForm'
import { UPDATE_PAYMENT_METHOD } from 'graphql/mutations/user'
import { PAYMENT_METHOD, SUBSCRIPTION, USER } from 'graphql/queries/user'
import useGraphQLErrors from 'hooks/useGraphQLErrors'
import useThreeDSecure from 'hooks/useThreeDSecure'
import { logError } from 'logService'
import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { type MeQuery, type MyPaymentMethodQuery, type UpdatePaymentMethodMutation } from '@/types/graphql'

import PaymentMethod from './PaymentMethod'

const errorMessages = {
  ERROR_PAYMENT_METHOD_REFUSED: 'Une erreur est survenue lors de la validation de votre moyen de paiement',
  ERROR_GENERIC: `Oups, une erreur est survenue, merci de contacter ${process.env.REACT_APP_CONTACT_ADDRESS}`,
}

const PaymentMethodManagement = () => {
  const { handleGraphQLErrors } = useGraphQLErrors()
  const userQuery = useQuery<MeQuery>(USER)
  const paymentMethodQuery = useQuery<MyPaymentMethodQuery>(PAYMENT_METHOD, { fetchPolicy: 'network-only' })
  const [editPaymentMethod] = useMutation<UpdatePaymentMethodMutation>(UPDATE_PAYMENT_METHOD)

  const [editMode, setEditionMode] = useState(false)

  const myPaymentMethod = useMemo(() => {
    return paymentMethodQuery?.data?.me?.paymentMethod
  }, [paymentMethodQuery])

  const handleToggleEdit = useCallback(() => {
    setEditionMode(!editMode)
  }, [setEditionMode, editMode])

  const handleSuccess = () => {
    setEditionMode(false)
    toast.success('Votre moyen de paiement a été mis à jour avec succès', { position: 'top-center' })
  }

  const { handle3DS } = useThreeDSecure(handleSuccess)

  const handleFormValidated = useCallback(async ({ paymentMethod }: StripePaymentMethodFormType) => {
    try {
      const { data } = await editPaymentMethod({
        variables: {
          paymentMethodId: paymentMethod,
        },
        refetchQueries: [
          {
            query: SUBSCRIPTION,
          },
          {
            query: PAYMENT_METHOD,
          },
        ],
        awaitRefetchQueries: true,
      })

      if (data?.updatePaymentMethod?.threeDSRedirectUrl != null) {
        handle3DS(data.updatePaymentMethod.threeDSRedirectUrl)

        return
      }

      handleSuccess()
    } catch (error) {
      handleGraphQLErrors(
        error,
        errorMessages,
        errorMessages.ERROR_GENERIC,
      )
      logError(error)
    }
  }, [handleGraphQLErrors, editPaymentMethod, handle3DS])

  if (
    userQuery.loading ||
    paymentMethodQuery.loading ||
    !userQuery.data?.me ||
    !paymentMethodQuery.data?.me
  ) {
    return null
  }

  if (editMode) {
    return (
      <>
        <ModalThreeDS />
        <PaymentMethodForm
          editMode={ true }
          onCancel={ handleToggleEdit }
          onFormValidated={ handleFormValidated }
        />
      </>
    )
  }

  return (
    <PaymentMethod
      paymentMethod={ myPaymentMethod }
      onToggleEdit={ handleToggleEdit }
    />
  )
}

export default PaymentMethodManagement
