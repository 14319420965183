import ContactFooter from 'components/ContactFooter'
import { Nav } from 'components/dpc'
import Footer from 'components/Footer'
import { type FC, type PropsWithChildren } from 'react'

type Props = {
  headerWithBackground?: boolean
}

const DpcLayout: FC<PropsWithChildren<Props>> = ({ children, headerWithBackground = true }) => {
  return (
    <>
      <Nav background={ headerWithBackground } />
      {children}
      <ContactFooter />
      <Footer />
    </>
  )
}

export default DpcLayout
