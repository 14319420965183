import Footer from 'components/Footer'
import SlimHeader from 'components/SlimHeader'
import { type FC, type PropsWithChildren } from 'react'
import styled from 'styled-components'

const PolicyLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Wrapper>
      <SlimHeader />
      <Content>
        { children }
      </Content>
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Content = styled.div`
  flex: 1;
  padding: 2rem 4rem;
`

export default PolicyLayout
