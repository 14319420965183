import { useQuery } from '@apollo/client'
import { Button } from 'components/atoms'
import HeaderUser from 'components/HeaderUser/HeaderUser'
import { NavAnchor } from 'components/Nav'
import AuthLinks from 'components/Nav/AuthLinks'
import { AVAILABLE_PLANS } from 'graphql/queries/plan'
import { USER } from 'graphql/queries/user'
import styled from 'styled-components'
import { colors } from 'theme'
import { isUbipharm } from 'utils/origin'
import { hasSpecialOffer } from 'utils/plans'

const LandingNav = () => {
  const userQuery = useQuery(USER)
  const isLogged = Boolean(userQuery?.data?.me)
  const availablePlanQuery = useQuery(AVAILABLE_PLANS)

  const SubscriptionLink = (
    <>
      {'S\'abonner'}
      {
        (
          !availablePlanQuery.loading
          && availablePlanQuery.data
          && hasSpecialOffer(availablePlanQuery.data.availablePlans)
        ) || isUbipharm()
          ? <Gift>Offre spéciale</Gift>
          : null
      }
    </>
  )

  const navItems = [
    { href: '#presentation', content: 'Présentation' },
    { href: '#about-us', content: 'À propos' },
    { href: '#subscriptions', content: SubscriptionLink },
    { href: '#contact', content: 'Contact' },
  ]

  return (
    <NavAnchor navItems={ navItems }>
      <Right>
        <Button
          className="button"
          href="/dpc"
          variant="secondary"
        >
          Formation DPC
        </Button>
        { isLogged
          ? (
              <HeaderUser
                avatar={ userQuery.data.me.avatar }
                className="margin-left"
                username={ userQuery.data.me.username }
              />
            )
          : <AuthLinks />
        }
      </Right>
    </NavAnchor>
  )
}

const Right = styled.div`
  display: flex;
  align-items: center;
  margin: auto 0 auto auto;

  > a {
    padding: 1rem;
    background: ${colors.purple};
    border: 0;
    border-radius: 50px;
  }

  .margin-left {
    margin-left: 1rem;
  }

  .button {
    color: ${colors.white};
  }

  @media (width <= 820px) {
    flex-direction: column;
  }
`

const Gift = styled.span`
  padding: 0.5rem;
  margin: 0.5rem;
  font-family: 'Brother 1816';
  font-size: 0.625rem;
  font-weight: 800;
  white-space: nowrap;
  background-image: linear-gradient(to bottom right, rgb(35 188 186), rgb(0 229 166));
  border-radius: 1.875rem;
`

export default LandingNav
