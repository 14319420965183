import { useQuery } from '@apollo/client'
import { Button } from 'components/atoms'
import { USER } from 'graphql/queries/user'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from 'theme'

const Subscribe = () => {
  const navigate = useNavigate()
  const userQuery = useQuery(USER)
  const isLogged = Boolean(userQuery?.data?.me)

  return (
    <Wrapper>
      <Title>
        {
          `Pour que le métier reste à la hauteur des enjeux actuels de la pharmacie, restons compétents.
          Mais`
        }
        <TitleEmphasis>
          {' en s’amusant'}
        </TitleEmphasis>
        .
      </Title>
      { isLogged
        ? null
        : (
            <SubscribeButton
              size="medium"
              variant="primary"
              onClick={ () => navigate('/register') }
            >
              {'Je m\'inscris'}
            </SubscribeButton>
          )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  margin: 0 20%;
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 900;
  color: ${colors.midnightBlue};
  text-align: center;
  white-space: pre-line;
`

const TitleEmphasis = styled.span`
  color: ${colors.lightGreen};
`

const SubscribeButton = styled(Button)`
  align-self: center;
  margin: 2rem 0;
`

export default Subscribe
