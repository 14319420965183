import { type FormEvent, useEffect, useRef, useState } from 'react'
import { type TypeOf, type ZodSchema } from 'zod'

type Errors<S> = {
  [K in keyof S]?: string;
}

export default function useForm<
  Schema extends ZodSchema,
  State = TypeOf<Schema>,
>(
  initialState: State,
  schema: Schema,
  submit: (data: State) => Promise<void>|void): [
    State,
    Errors<State>,
    (value: State[keyof State], field: keyof State) => void,
    (e: FormEvent<HTMLFormElement>) => void,
    (data: State) => void,
    (errors: Errors<State>) => void,
  ] {
  const [data, setData] = useState<State>(initialState)
  const [errors, setErrors] = useState<Errors<State>>({})
  const ref = useRef(false)

  useEffect(() => {
    ref.current = true

    return () => {
      ref.current = false
    }
  }, [])

  const handleChange = (value: State[typeof field], field: keyof State) => {
    setErrors({})

    setData({
      ...data,
      [field]: value,
    })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const parseData = schema.safeParse(data)

    if (parseData.success) {
      submit(data)

      return
    }

    setErrors(
      parseData.error.issues.reduce((issues, aggregatedIssues) => {
        const parsedIssues = aggregatedIssues.path.reduce((newIssues, path) => ({
          ...newIssues,
          [path]: aggregatedIssues.message,
        }), {})

        return {
          ...issues,
          ...parsedIssues,
        }
      }, {}),
    )
  }

  return [data, errors, handleChange, handleSubmit, setData, setErrors]
}
