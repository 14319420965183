import AuthLayout from 'components/layouts/AuthLayout'
import ResetPasswordForm from 'components/ResetPassword'

const ResetPassword = props => (
  <AuthLayout>
    <ResetPasswordForm { ...props } />
  </AuthLayout>
)

export default ResetPassword
