import { Icon } from 'components/atoms'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'

const RecapItem = ({ children }) => (
  <Li>
    <Icon
      fill={ colors.lightGreen }
      name="heavyCheck"
      size="0.7rem"
    />
    <Text>
      {children}
    </Text>
  </Li>
)

RecapItem.propTypes = {
  children: PropTypes.node.isRequired,
}

const Li = styled.li`
  display: flex;
  flex-shrink: 0;
  margin-bottom: 1.5rem;
  list-style-type: none;

  & > svg {
    min-width: 0.7rem;
  }
`

const Text = styled.span`
  margin-left: 0.75rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  color: ${colors.midnightBlue};
  opacity: 0.5;
`

export default RecapItem
