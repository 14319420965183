import { type FC } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

type Props = {
  title: string
}

const SectionMarker: FC<Props> = ({ title }) => (
  <Wrapper>
    <Shape>
      <div />
    </Shape>
    <span>
      {title}
    </span>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1.25rem;

  & > span {
    font-family: 'Brother 1816';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: bold;
    color: ${colors.lightGreen};
  }
`

const Shape = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 3px;
  margin-right: 1rem;
  border: 2px solid ${colors.lightGreen};
  border-radius: 4px;
  transform: rotate(-120deg);

  & > div {
    display: flex;
    flex-shrink: 0;
    padding: 3.5px;
    border: 2px solid ${colors.lightGreen};
    border-radius: 4px;
  }
`

export default SectionMarker
