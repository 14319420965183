import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { LOGIN_PATH } from 'components/Router'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'

const cache = new InMemoryCache()

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql/`,
})

// Constants for cookie names
/**
 * TEMP_SWITCH_TOKEN is used to temporarily save the main user's token while impersonating another user.
 * This allows us to retrieve it once they log out from impersonation.
 */
const TEMP_SWITCH_TOKEN = 'tempSwitchToken'

/**
 * AUTH_TOKEN is used to store the user's authentication token.
 */
const AUTH_TOKEN = 'authToken'

/**
 * SWITCH_TOKEN is used for managing the user switching (impersonation) process.
 */
const SWITCH_TOKEN = 'switchToken'

/**
 * BASIC_TOKEN is used for basic authentication if needed.
 */
const BASIC_TOKEN = 'basicToken'

const authLink = setContext((_, { headers }) => {
  const authToken = Cookies.get(AUTH_TOKEN)
  const switchToken = Cookies.get(SWITCH_TOKEN)

  if (switchToken !== undefined) {
    Cookies.remove(AUTH_TOKEN)
    sessionStorage.setItem(TEMP_SWITCH_TOKEN, switchToken)
    Cookies.remove(SWITCH_TOKEN, { path: '/', domain: process.env.REACT_APP_DOMAIN })
    window.location.href = `${LOGIN_PATH}?redirected=true`

    return
  }

  if (authToken !== undefined) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${authToken}`,
      },
    }
  }

  const basicToken = Cookies.get(BASIC_TOKEN)

  if (basicToken !== undefined) {

    return {
      headers: {
        ...headers,
        authorization: `Basic ${basicToken}`,
      },
    }
  }

  return {
    headers: {
      ...headers,
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    toast.error(`Vos identifiants sont erronés. Vous pouvez envoyer un mail à ${process.env.REACT_APP_CONTACT_ADDRESS} pour plus d'informations.`, {
      position: 'top-center',
    })

    Cookies.remove(AUTH_TOKEN)
    Cookies.remove(BASIC_TOKEN)

    setTimeout(client.resetStore, 100)

    return
  }

  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        '[GraphQL error]: Message: ', message,
        'Location: ', locations,
        'Path: ', path,
      ),
    )
  }

  // this should not be needed if all api calls are properly handled
  if (networkError) {
    if (networkError.statusCode === undefined) {
      return
    }

    const {
      statusCode,
      result: { error },
    } = networkError

    console.log(`[Network error]: ${statusCode} ${error ? error.message : ''}`)
  }
})

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    errorLink,
    authLink,
    httpLink,
  ]),
})

export default client
