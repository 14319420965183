import { LOGIN_PATH, REGISTER_PATH } from 'components/Router'
import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from 'theme'

type Props = {
  isLoginSelected: boolean
}

const AuthNav: FC<Props> = ({
  isLoginSelected,
}) => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <AuthButton
        $selected={ isLoginSelected }
        onClick={ () => navigate(LOGIN_PATH) }
      >
        SE CONNECTER
      </AuthButton>
      <AuthButton
        $selected={ !isLoginSelected }
        onClick={ () => navigate(REGISTER_PATH) }
      >
        S&apos;INSCRIRE
      </AuthButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
`
type AuthButtonProps = {
  $selected: boolean
}

const AuthButton = styled.button<AuthButtonProps>`
  margin: 1rem 2rem;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.midnightBlue};
  ${({ $selected }) => !$selected ? 'opacity: 0.5;' : ''}
  user-select: none;
`

export default AuthNav
