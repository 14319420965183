import { useQuery } from '@apollo/client'
import ReassuranceItems from 'components/ReassuranceItems'
import { DrawerContext } from 'context/Drawer'
import { AVAILABLE_PLANS } from 'graphql/queries/plan'
import { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import { getDiscountModalData, isPharmUppUltra } from 'utils/origin'
import { hasSpecialOffer } from 'utils/plans'

import Separator from '../Separator'
import SubscriptionCard from './SubscriptionCard'

const Subscriptions = () => {
  const { actions } = useContext(DrawerContext)
  const consortiumData = useMemo(() => getDiscountModalData()?.consortium, [])
  const { data: plansData, loading } = useQuery(AVAILABLE_PLANS)
  const { setPlan } = actions

  if (loading || plansData === undefined) {
    return null
  }

  const hasBanner = plansData.availablePlans && hasSpecialOffer(plansData.availablePlans)

  return (
    <Wrapper id="subscriptions">
      <Title>
        Nos abonnements
      </Title>
      {
        !isPharmUppUltra()
          ? (
              <>
                <DiscountWrapper>
                  <Discount>
                    Testez Défimédoc pendant
                    {' '}
                    { consortiumData ? consortiumData.trialPeriod : '14 jours gratuitement et sans engagement !' }
                  </Discount>
                  <StyledSeparator />
                </DiscountWrapper>
              </>
            )
          : null
      }
      {
        (plansData.availablePlans.length > 0)
          ? (
              <>
                <SubscriptionsList $hasBanner={ hasBanner }>
                  {plansData.availablePlans.map((plan, key) => (
                    <li key={ key }>
                      <SubscriptionCard
                        { ...plan }
                        onClick={ () => setPlan(plan) }
                      />
                    </li>
                  ),
                  )}
                </SubscriptionsList>
              </>
            )
          : (
              <>
                <Contact>
                  <span>Vous souhaitez augmenter votre nombre de licences ? Contactez-nous sur </span>
                  <a href={ `mailto:${process.env.REACT_APP_CONTACT_ADDRESS}` }>{process.env.REACT_APP_CONTACT_ADDRESS}</a>
                </Contact>
              </>
            )}

      <ReassuranceItems />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  font-family: "Brother 1816";
  font-size: 2.25rem;
  font-style: bold;
  font-weight: bold;
  color: ${colors.midnightBlue};
  text-align: center;
`

const DiscountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`

const Discount = styled.p`
  margin: 0 0 1rem;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.25rem;
  color: ${colors.midnightBlue};
  text-align: center;
`

const StyledSeparator = styled(Separator)`
  align-self: center;
  width: 4.4rem;
  margin: 0;
`

const SubscriptionsList = styled.ul`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: ${({ $hasBanner }) => $hasBanner ? 'center' : 'stretch'};
  justify-content: center;
  margin-bottom: 4.375rem;

  & > li {
    display: flex;
    margin-top: 2rem;
    margin-right: 1.6875rem;
    list-style-type: none;
  }
`

const Contact = styled.div`
  margin-top: 4.375rem;
  margin-bottom: 4.375rem;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.midnightBlue};
  text-align: center;
`

export default Subscriptions
