import DrawerBottom from './DrawerBottom'
import DrawerTop from './DrawerTop'

const DrawerContent = props => (
  <>
    <DrawerTop { ...props } />
    <DrawerBottom { ...props } />
  </>
)

export default DrawerContent
