import PropTypes from 'prop-types'
import React, { useState } from 'react'

const CartContext = React.createContext()

const CartProvider = ({ children }) => {
  const savedCart = localStorage.getItem('cart')
  const defaultCart = savedCart !== null ? JSON.parse(savedCart) : null
  const [cart, setCart] = useState(defaultCart)

  const handleCartUpdate = updatedCart => {
    localStorage.setItem('cart', JSON.stringify(updatedCart))
    setCart(updatedCart)
  }

  return (
    <CartContext.Provider
      value={ {
        cart,
        actions: { setCart: handleCartUpdate },
      } }

    >
      {children}
    </CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CartProvider
export { CartContext }
