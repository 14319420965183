import {
  Button,
  FormInput,
  FormSelect,
} from 'components/atoms'
import Form from 'components/atoms/Form'
import { InputType } from 'components/atoms/Input'
import { countries as countriesList } from 'constants/countries'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

const countriesOptions = countriesList.map(country => ({
  label: country,
  value: country,
}))

const PharmacyForm = ({
  editionMode,
  errors,
  form,
  onChange,
  onCancel,
  onSubmit,
}) => {
  const {
    name,
    address,
    city,
    zipCode,
    country,
  } = form

  const selectedCountry = useMemo(() => {
    return countriesOptions.find(countryOption => countryOption.value === country)
  }, [country])

  return (
    <div>
      <Form
        noValidate
        onSubmit={ onSubmit }
      >
        <FormInput
          error={ errors.name }
          isRequired
          label="Nom de la pharmacie"
          name="name"
          type={ InputType.TEXT }
          value={ name }
          onChangeText={ onChange }
        />
        <FormInput
          error={ errors.address }
          isRequired
          label="Adresse"
          name="address"
          type={ InputType.TEXT }
          value={ address }
          onChangeText={ onChange }
        />
        <FormInput
          error={ errors.city }
          isRequired
          label="Ville"
          name="city"
          type={ InputType.TEXT }
          value={ city }
          onChangeText={ onChange }
        />
        <Row>
          <InputWrapper>
            <FormInput
              error={ errors.zipCode }
              isRequired
              label="Code postal"
              name="zipCode"
              type={ InputType.TEXT }
              underline={ true }
              value={ zipCode }
              onChangeText={ onChange }
            />
          </InputWrapper>
          <InputWrapper>
            <FormSelect
              error={ errors.country }
              isRequired
              label="Pays"
              name="country"
              options={ countriesOptions }
              selectedOption={ selectedCountry }
              onChange={ onChange }
            />
          </InputWrapper>
        </Row>
        { editionMode
          && (
            <EditionButtons>
              <CancelEdition
                size="small"
                variant="tertiary"
                onClick={ onCancel }
              >
                Annuler
              </CancelEdition>
              <ConfirmEdition
                size="small"
                type="submit"
                variant="primary"
              >
                Valider
              </ConfirmEdition>
            </EditionButtons>
          )}
      </Form>
    </div>
  )
}

PharmacyForm.propTypes = {
  editionMode: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  form: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    zipCode: PropTypes.string,
    country: PropTypes.string.isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const Row = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;

  & > div:last-child {
    margin-right: 0;
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 1.3125rem;
`

const EditionButtons = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-top: 2rem;
`

const CancelEdition = styled(Button)`
  && {
    margin-right: 1rem;
  }
`

const ConfirmEdition = styled(Button)`
  && {
    padding: 0.83rem 1.5rem;
    background-color: ${colors.lightGreen};
    background-image: none;
    border-radius: 0;
    box-shadow: none;
  }
`

export default PharmacyForm
