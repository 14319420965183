import { type FC } from 'react'
import styled from 'styled-components'
import { colors, sizes } from 'theme'

const Hero: FC = () => (
  <Wrapper>
    <BackGround />
    <Content>
      <Title>
        <StyledDefimedoc>défimédoc</StyledDefimedoc>
        {' '}
        vous accompagne plus loin
      </Title>
      <SubTitle>
        pour valider votre <em>DPC</em>
      </SubTitle>
      <Logos>
        <div className="qualiopi">
          <img
            alt="Certification Qualiopi"
            className="logo logo-qualiopi"
            src={ require('assets/images/dpc/qualiopi.png') }
            title="Certification Qualiopi"
          />
          <p>Certificat communiqué sur demande</p>
        </div>
        <img
          alt="Conseil National Professionnel de la Pharmacie"
          className="logo logo-cnp"
          src={ require('assets/images/dpc/cnp.png') }
          title="Conseil National Professionnel de la Pharmacie"
        />
      </Logos>
    </Content>
  </Wrapper>
)

const BackGround = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url(${require('assets/images/dpc/background-top.png')});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
`

const Title = styled.h1`
  margin: 0 0 1rem;
`

const StyledDefimedoc = styled.span`
  color: ${colors.lightGreen};
`

const SubTitle = styled.p`
  margin: 0 0 3rem;
  font-family: 'Brother 1816';
  font-size: 2.375em;
  font-weight: 900;
  line-height: 58px;
`

const Content = styled.div`
  max-width: 51.25rem;
  padding: ${sizes.heroesPaddingTop} 1rem 12rem;
  margin: auto;
`

const Logos = styled.div`
  position: absolute;
  bottom: 0.5rem;
  display: flex;
  gap: 9rem;
  align-items: center;
  justify-content: space-between;

  > div.qualiopi > p {
    margin-bottom: 2.5rem;
    font-family: Montserrat;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: normal;
    line-height: 0rem;
    color: ${colors.midnightBlue};
    text-align: center;
  }

  @media (width <= 768px) {
    bottom: -1.5rem;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    > div.qualiopi > p {
      margin-bottom: 0;
    }
  }
`

const Wrapper = styled.header`
  position: relative;
  color: white;

  em {
    font-style: normal;
    color: ${colors.lightGreen};
  }

  img.logo {
    margin-left: 1rem;
  }

  img.logo-qualiopi {
    height: 119px;
    margin-top: 1rem;

    @media (width <= 768px) {
      height: 80px;
    }
  }

  img.logo-cnp {
    height: 200px;
    margin-right: 1rem;

    @media (width <= 768px) {
      height: 120px;
    }
  }

  button {
    margin: 3rem 0;
  }

  @media (width <= 1050px) {
    h1 {
      margin-top: ${sizes.heroesTitleMobileMarginTop};
    }
  }
`

export default Hero
