import { useQuery } from '@apollo/client'
import { USER } from 'graphql/queries/user'

import HeaderUser from './HeaderUser'

const Container = props => {
  const userQuery = useQuery(USER)

  if (userQuery.loading || !userQuery.data?.me) {
    return null
  }

  const {
    avatar,
    username,
  } = userQuery.data.me

  return (
    <HeaderUser
      avatar={ avatar }
      username={ username }
      { ...props }
    />
  )
}

export default Container
