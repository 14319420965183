import PropTypes from 'prop-types'
import React, { useState } from 'react'

const initialState = {
  isOpen: false,
  isDailyQuestionModalOpen: false,
  isThreeDSModalOpen: false,
  title: '',
  details: '',
  consortium: {
    name: '',
    abonnementType: '',
    period: [
      { oldPeriod: '', newPeriod: '' },
    ],
    discounts: [
      { amount: '', nbLicences: '' },
    ],
    descriptions: [
      { message: '' },
    ],
  },
  cancel: {
    text: '',
    handleClick: () => {},
  },
  validate: {
    text: '',
    handleClick: () => {},
  },
}

const ModalContext = React.createContext()

const ModalProvider = ({ children }) => {
  const [state, setState] = useState({
    ...initialState,
  })

  const closeModal = () => {
    setState({
      ...initialState,
      isOpen: false,
    })
  }

  const toggleDailyQuestionModal = () => {
    setState({
      ...initialState,
      isDailyQuestionModalOpen: !state.isDailyQuestionModalOpen,
    })
  }

  const toggleThreeDSModal = () => {
    setState({
      ...initialState,
      isThreeDSModalOpen: !state.isThreeDSModalOpen,
    })
  }

  const closeThreeDSModal = () => {
    setState({
      ...initialState,
      isThreeDSModalOpen: false,
    })
  }

  const setModal = ({
    title,
    details,
    consortium,
    cancel,
    validate,
  }) => {
    setState({
      ...state,
      title,
      details,
      consortium,
      cancel,
      validate,
      isOpen: true,
    })
  }

  return (
    <ModalContext.Provider
      value={ {
        state: { ...state },
        actions: {
          closeModal,
          toggleDailyQuestionModal,
          toggleThreeDSModal,
          closeThreeDSModal,
          setModal,
        },
      } }
    >
      { children }
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ModalProvider
export { ModalContext }
