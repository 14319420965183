import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_MY_PROFILE } from 'graphql/mutations/user'
import { USER } from 'graphql/queries/user'
import { logError } from 'logService'
import { toast } from 'react-toastify'

import AvatarEditor from './AvatarEditor'

const Container = () => {
  const userQuery = useQuery(USER)
  const [updateMyProfile] = useMutation(UPDATE_MY_PROFILE)

  if (userQuery.loading || !userQuery.data?.me) {
    return null
  }

  const handleChange = async e => {
    try {
      await updateMyProfile({
        variables: {
          user: {
            avatar: e.target.files[0],
          },
        },
        refetchQueries: [{
          query: USER,
        }],
        awaitRefetchQueries: true,
      })
    } catch (error) {
      toast.error('Oups, une erreur est survenue', {
        position: 'top-center',
      })
      logError('GRAPHQL', 'updateMyProfile', error)
    }
  }

  return (
    <AvatarEditor
      avatar={ userQuery.data?.me?.avatar }
      onChange={ handleChange }
    />
  )
}

export default Container
