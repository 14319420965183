import { Button } from 'components/atoms'
import styled from 'styled-components'
import { colors } from 'theme'

const AuthLinks = () => (
  <LoginWrapper className="text-description">
    <Button
      href="/login"
    >
      Connexion
    </Button>
    <span className="separator">/</span>
    <Button
      href="/register"
    >
      Inscription
    </Button>
  </LoginWrapper>
)

const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.white};

  a {
    color: ${colors.white};
    background: 0;
  }

  .separator {
    font-weight: bold;
  }
`

export default AuthLinks
