import {
  Button,
} from 'components/atoms'
import { rgba } from 'polished'
import { type FC, type ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

type Props = {
  buttonLabel: string
  buttonOnClick: () => void
  content: ReactNode
  headerBottom: ReactNode
  headerMiddle: ReactNode
  headerTop: ReactNode
}

const Card: FC<Props> = ({
  buttonLabel,
  buttonOnClick,
  content,
  headerBottom,
  headerMiddle,
  headerTop,
}) => (
  <Wrapper>
    <Header>
      <HeaderTopWrapper>
        {headerTop}
      </HeaderTopWrapper>
      <HeaderMiddleWrapper>
        {headerMiddle}
      </HeaderMiddleWrapper>
      <HeaderBottomWrapper>
        {headerBottom}
      </HeaderBottomWrapper>
    </Header>
    <Body>
      {content}
      <DetailsButton
        variant="secondary"
        onClick={ buttonOnClick }
      >
        {buttonLabel}
      </DetailsButton>
    </Body>
  </Wrapper>
)

const borderRadius = '8'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  border-radius: ${borderRadius}px;
  box-shadow: 0 3px 24px 0 rgb(0 0 0 / 12%);
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 1.125rem;
  padding-bottom: 1.3125rem;
  color: ${colors.white};
  background-image: linear-gradient(107deg, ${colors.blueLagoon}, ${colors.midnightBlue});
  border-radius: 8px 8px 0 0;
`

const HeaderTopWrapper = styled.span`
  margin-bottom: 1.25rem;
  font-family: 'Brother 1816';
  font-style: normal;
  font-weight: 500;
  color: ${colors.white};
  user-select: none;
`

const HeaderMiddleWrapper = styled.span`
  margin-bottom: 1.3125rem;
  font-family: 'Brother 1816';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  color: ${colors.white};
  user-select: none;
`

const HeaderBottomWrapper = styled.span`
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
`

const bottomGradient = 'rgba(143, 171, 229, 0)'

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 12.625rem;
  padding: 1rem;
  font-family: 'Brother 1816';
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.375rem;
  color: ${colors.midnightBlue};
  border: 1px solid;
  border-image: linear-gradient(to bottom, ${rgba(colors.hanBlue, 0.6)}, ${bottomGradient});
  border-image-slice: 1;
`

const DetailsButton = styled(Button)`
  && {
    color: ${colors.lightGreen};
  }
`
export default Card
