import { useMutation, useQuery } from '@apollo/client'
import { DEFAULT_COUNTRY } from 'constants/countries'
import { mandatoryField } from 'constants/form'
import { UPDATE_MY_PHARMACY } from 'graphql/mutations/user'
import { PHARMACY } from 'graphql/queries/user'
import { logError } from 'logService'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import PharmacyForm from './PharmacyForm'

const Container = () => {
  const pharmacyQuery = useQuery(PHARMACY)
  const [updateMyPharmacy] = useMutation(UPDATE_MY_PHARMACY)

  const [initialLoad, setInitialLoad] = useState(true)
  const [form, setForm] = useState({
    name: '',
    address: '',
    zipCode: '',
    city: '',
    country: DEFAULT_COUNTRY,
  })
  const [errors, setErrors] = useState({})
  const [editionMode, setEditionMode] = useState(false)

  const fillForm = useCallback(() => {
    let { pharmacy } = pharmacyQuery.data?.me ?? {}
    pharmacy = pharmacy !== null ? pharmacy : {}

    let {
      name,
      address,
      zipCode,
      city,
      country,
    } = pharmacy

    name = name != null ? name : ''
    address = address != null ? address : ''
    zipCode = zipCode != null ? zipCode : ''
    city = city != null ? city : ''
    country = country != null ? country.value : DEFAULT_COUNTRY

    setInitialLoad(false)

    setForm({
      ...form,
      name,
      address,
      zipCode,
      city,
      country,
    })
  }, [form, pharmacyQuery])

  useEffect(() => {
    if (!initialLoad) {
      return
    }

    if (!pharmacyQuery.loading && undefined !== pharmacyQuery?.data?.me) {
      fillForm()
    }
  }, [initialLoad, pharmacyQuery.loading, pharmacyQuery.data?.me, form, fillForm])

  const handleChange = (value, name) => {
    setEditionMode(true)
    let updatedErrors = { ...errors }

    updateFormValue(value, name)

    setErrors({
      ...updatedErrors,
      [name]: null,
    })
  }

  const updateFormValue = (value, name) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const {
      name,
      address,
      zipCode,
      city,
      country,
    } = form
    const updatedErrors = {}

    if (name.length === 0) {
      updatedErrors.name = mandatoryField
    }
    if (address.length === 0) {
      updatedErrors.address = mandatoryField
    }
    if (zipCode.length === 0) {
      updatedErrors.zipCode = mandatoryField
    }
    if (city.length === 0) {
      updatedErrors.city = mandatoryField
    }
    if (country.length === 0) {
      updatedErrors.country = mandatoryField
    }

    setErrors(updatedErrors)
    const hasError = Object.values(updatedErrors).some(error => typeof error === 'string')

    if (!hasError) {
      try {
        await updateMyPharmacy({
          variables: {
            pharmacy: {
              name,
              address,
              zipCode,
              city,
              country: country.value,
            },
          },
          refetchQueries: [{
            query: PHARMACY,
          }],
          awaitRefetchQueries: true,
        })
        setEditionMode(false)
        document.activeElement.blur()
      } catch (error) {
        toast.error('Oups, une erreur est survenue', {
          position: 'top-center',
        })
        logError('GRAPHQL', 'updateMyPharmacy', error)
      }
    }
  }

  const handleCancel = () => {
    setEditionMode(false)
    setErrors({})
    fillForm()
  }

  return (
    <PharmacyForm
      editionMode={ editionMode }
      errors={ errors }
      form={ form }
      onCancel={ handleCancel }
      onChange={ handleChange }
      onSubmit={ handleSubmit }
    />
  )
}

export default Container
