import { useQuery } from '@apollo/client'
import { INVOICES } from 'graphql/queries/user'

import Invoices from './Invoices'

const Container = () => {
  const userQuery = useQuery(INVOICES)

  if (userQuery.loading || !userQuery.data?.me) {
    return null
  }

  return (
    <Invoices invoices={ userQuery.data.me.invoices } />
  )
}

export default Container
