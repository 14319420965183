import { FORGOTTEN_PASSWORD_PATH, LOGIN_PATH } from 'components/Router'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import AuthNav from './AuthNav'
import ForgottenPassword from './ForgottenPassword'
import Login from './Login'
import Register from './Register'

const Container = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isLoginSelected = useMemo(() => location.pathname === LOGIN_PATH, [location.pathname])

  return (
    <Wrapper>
      {
        location.pathname === FORGOTTEN_PASSWORD_PATH
          ?
            <ForgottenPassword onBack={ () => navigate(-1) } />
          : (
              <>
                <AuthNav
                  isLoginSelected={ isLoginSelected }
                />
                { isLoginSelected
                  ?
                    <Login />
                  :
                    <Register />
                }
              </>
            )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: center;
  width: 65%;
  padding: 3.7rem 0;
`

export default Container
